import React from "react";
import "./forms.css";

const FormSpacerHeight = {
    Small: 1,
    Regular: 2,
    Large: 3
}

function FormSpacer(props) {
    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }

    const getClassName = () => {
        switch (props.Height) {
            case FormSpacerHeight.Small:
                return "eone-ui-form-spacer-small";
            case FormSpacerHeight.Large:
                return "eone-ui-form-spacer-large";
            default:
                return "eone-ui-form-spacer";
        }
    };
    
    return (
        <div className={getClassName()}/>
    );
}

export {FormSpacer, FormSpacerHeight};