import { faCheck, faPlus, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FormSelect } from "eone-ui/forms/form-select";
import { FontAwesome } from "eone-ui/icons/icons";
import { Navigation } from "eone-ui/navigation/navigation";
import { FormPanel } from "eone-ui/panels/form-panel";
import { data_get, data_post } from "popdock-shared/Functions/data-access";
import { ConnectorAuthSetting } from "popdock-shared/connector-auth/connector-auth-setting";
import React, { useState, useEffect } from "react";
import { FormSpacer } from "eone-ui/forms/form-spacer";
import { AddConnectorMessage } from "./add-connector-message";
import { getJSON } from "./get-json";

function getUrlPathItem(index) {
    const path = window.location.pathname.split("/");
    if (path.length > index) {
        return path[index];
    }
    return null;
}

function AddConnector(props) {

    const [connectorSetupId, setConnectorSetupId] = useState(null);
    const [connectorSetup, setConnectorSetup] = useState({});
    const [authDocumentation, setAuthDocumentation] = useState([]);
    const [authsLoading, setAuthsLoading] = useState(true);
    const [settingsLoading, setSettingsLoading] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [validating, setValidating] = useState(false);
    const [valid, setValid] = useState(null);
    const [error, setError] = useState(null);
    const [auths, setAuths] = useState([]);
    const [authId, setAuthId] = useState(null);
    const [settings, setSettings] = useState([]);


    useEffect(() => {
        const load = async () => {
            const connectorSetupId = getUrlPathItem(2);
            setConnectorSetupId(connectorSetupId);
            if (connectorSetupId) {
                loadConnectorSetup(connectorSetupId);
                const urlParameters = new URLSearchParams(window.location.search);
                if (urlParameters.has("error")) {
                    const newError = urlParameters.get("error");
                    if (newError) {
                        setError(newError);
                    }
                }
                else if (urlParameters.has("valid")) {
                    const newValid = urlParameters.get("valid");
                    if (newValid === "true") {
                        setValid(true);
                    }
                }
            }
        }
        if (props.User) {
            load();
        }
    }, [props.User]);

    const loadConnectorSetup = async (connectorSetupId) => {
        props.SetConnectorSetupId(connectorSetupId);
        setAuthsLoading(true);
        const newConnectorSetup = await data_get(`/AddConnector/ConnectorSetup/${connectorSetupId}`);
        setConnectorSetup(newConnectorSetup);
        const newAuthDocumentation = await getJSON(props.SettingsBaseUrl, "setup/connector-auths", `${newConnectorSetup.AuthsFile}.json`);

        setAuthDocumentation(newAuthDocumentation ?? []);
        loadAuths(connectorSetupId, newAuthDocumentation ?? []);
    }

    const loadAuths = async (connectorSetupId, newAuthDocumentation) => {
        setAuthsLoading(true);
        const response = await fetch(`/AddConnector/Auths/${connectorSetupId}`)
        const { defaultAuthId, connectorAuths } = await response.json();
        setAuths(connectorAuths);
        setAuthId(defaultAuthId);
        setAuthsLoading(false);
        setAuthId(defaultAuthId);
        if (defaultAuthId) {
            const authDoc = newAuthDocumentation.find(doc => doc.ConnectorAuthId === defaultAuthId);
            if (authDoc && authDoc.HelpArticle) {
                const article = await getJSON(props.SettingsBaseUrl, "documentation", `help-articles/${authDoc.HelpArticle}.json`);

                props.SetHelpArticle(article);
            }
        }
        await loadSettings(defaultAuthId);

    }

    const loadSettings = async (authId) => {
        setSettings([]);
        setSettingsLoading(true);
        const newSettings = await data_get(`/AddConnector/Settings/${authId}`);
        newSettings.sort((a, b) => {
            if (a.SettingNumber > b.SettingNumber) return 1;
            if (a.SettingNumber < b.SettingNumber) return -1;
            return 0;
        });
        setSettings(newSettings);
        setSettingsLoading(false);
    }

    const changeSetting = (name, value) => {
        const newSettings = [...settings];
        const setting = newSettings.find(s => s.Name === name);
        if (setting) {
            setting.Value = value;
            setSettings(newSettings);
        }
    };

    const requiredSettingsEntered = () => {
        for (let i = 0, len = settings.length; i < len; i++) {
            if (settings[i].Required && (settings[i].Value === "" || settings[i].Value === null)) {
                return false;
            }
        }
        return true;
    }

    const changeAuthOption = async (newAuthId) => {
        setAuthId(newAuthId);
        setError(null);
        setValid(null);
        loadSettings(newAuthId);

        const authDoc = authDocumentation.find(doc => doc.ConnectorAuthId === defaultAuthId);
        if (authDoc && authDoc.HelpArticle) {
            const article = await getJSON(props.SettingsBaseUrl, "documentation", `help-articles/${authDoc.HelpArticle}.json`);

            props.SetHelpArticle(article);
        }

    }

    const getSettings = () => {
        return settings.map((setting, index) => (
            <ConnectorAuthSetting
                key={setting.SettingId}
                Name={setting.Name}
                Label={setting.Label}
                Placeholder={setting.Placeholder}
                Tooltip={setting.Tooltip}
                Value={setting.Value}
                FieldType={setting.FieldType}
                Required={setting.Required}
                Options={setting.Options}
                AutoFocus={index === 0}
                Class={props.Class}
                OnChange={changeSetting} />
        ));
    };


    const getAuthsSelect = () => {
        if (auths && auths.length > 1) {
            const options = auths.map(auth => {
                return { Key: auth.ConnectorAuthId, Value: auth.DisplayName };
            });

            return <FormSelect Label={"Connection type"} Value={authId} Options={options} OnChange={changeAuthOption} />;
        }

        return null;
    }

    const getNavigationButtons = () => {
        return [
            {
                Label: connecting ? "Connecting..." : "Connect",
                Icon: connecting ? faSpinner : faPlus,
                IconSpin: connecting,
                OnClick: connect,
                Disabled: !requiredSettingsEntered() || connecting || validating
            },
            {
                Label: validating ? "Validating..." : "Validate",
                Icon: validating ? faSpinner : faCheck,
                IconSpin: validating,
                OnClick: validate,
                Disabled: !requiredSettingsEntered() || connecting || validating

            }
        ];
    }

    const connect = async () => {

        if (!requiredSettingsEntered()) {
            setError("Required settings not entered.");
            return;
        }

        const userValues = settings.map(setting => {
            return {
                Name: setting.Name,
                Value: setting.Value
            };
        });

        setConnecting(true);
        setValidating(false);
        setError(null);
        setValid(null);
        const connectData = await data_post(`/AddConnector/Connect/${authId}?connectorSetupId=${connectorSetupId}`, userValues);
        if (connectData.error) {
            setError(connectData.error);
            setConnecting(false);
        }
        if (connectData.Status === "error") {
            setError(connectData.Error);
            setConnecting(false);
        }
        else if (connectData.Status === "redirect") {
            window.location = connectData.RedirectTo;
        }
    }

    const validate = async () => {

        if (!requiredSettingsEntered()) {
            setError("Required settings not entered.");
            return;
        }

        const userValues = settings.map(setting => {
            return {
                Name: setting.Name,
                Value: setting.Value
            };
        });

        setValidating(true);
        setConnecting(false);
        setValid(null);
        setError(null);
        const connectData = await data_post(`/AddConnector/Validate/${authId}?connectorSetupId=${connectorSetupId}`, userValues);
        if (connectData.error) {
            setError(connectData.error);
            setValidating(false);
            setValid(false);
        }
        else if (connectData.Status === "error") {
            setError(connectData.Error);
            setValidating(false);
            setValid(false);
        }
        else if (connectData.Status === "valid") {
            setError(connectData.Error);
            setValidating(false);
            setValid(true);
        }
        else if (connectData.Status === "redirect") {
            window.location = connectData.RedirectTo;
        }
    }

    return (
        <Navigation ItemsLabel={"Steps"} Buttons={getNavigationButtons()} Items={props.NavigationItems} Selected={connectorSetupId}>
            <FormPanel Title={connectorSetup.Name} Icon={connectorSetup.Icon} IconType="af" Loading={authsLoading}>
                <div>
                    {authsLoading ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 12 }}><FontAwesome Icon={faSpinner} Spin />Loading...</div> :
                        getAuthsSelect()}
                    {settingsLoading ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 12 }}><FontAwesome Icon={faSpinner} Spin />Loading...</div> :
                        getSettings()}
                </div>
                <AddConnectorMessage
                    ErrorMessage={error}
                    ConnectMessage={connecting ? "Connecting..." : validating ? "Validating..." : null}
                    SuccessMessage={valid ? "Connection validated." : null} />
            </FormPanel>
        </Navigation>
    );
}

export { AddConnector };
