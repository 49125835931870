export const HelpArticleType = {
    Section: 1,
    Article: 2,
    CalculatedFieldLanguage: 3,
    ApiDocumentation: 4
}

export const HelpArticleStatus = {
    Stub: 1,
    Draft: 2,
    Submitted: 3,
    Approved: 4,
    Rejected: 5,
    Published: 6
}

export const HelpArticleItemType = {
    Text: 1,
    Image: 2,
    Video: 3,
    Iframe: 4,
    Title: 5,
    Subtitle: 6,
    Tip: 7,
    ListItem: 8,
    Link: 9,
    Code: 10,
    ListItemGroup: 1000
}

export const HelpArticleItemListType = {
    Bullet: 1,
    Number: 2
}

export const HelpArticleItemCodeType = {
    PowerShell: 1,
    PopdockScript: 2,
    Sql: 3,
    Json: 4,
    Xml: 5,
    Response: 1000
}

export const HelpArticleLinkedResourceType = {
    Page: 1,
    Section: 2,
    Field: 3
}

export const HelpArticleRelatedArticleType = {
    Article: 1,
    External: 2
}