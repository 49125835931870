import React, { Component } from "react";
import { ErrorPanel } from "eone-ui/panels/error-panel";

export class NotFoundErrorPanel extends Component {
    render() {
        return (
            <ErrorPanel
                Title="Not found"
                Message="Whatever you were looking for appears to have been moved, deleted or does not exist."
                Image="cloudy_notfound" />

        );
    }
}
