import React from "react";
import {getDataTestId} from "../functions/data-test-ids";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function InputCopyButton(props) {
    if (!props.Show) {
        return null;
    }

    return (
        <a className="eone-ui-input-copy" onClick={props.Copy} title="Copy to clipboard" data-testid={getDataTestId(props.DataTestId, "copy-button")}>
            <FontAwesomeIcon fixedWidth={true} icon={props.Icon}/>
        </a>
    )
}

export { InputCopyButton };