import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons";
import { getDataTestId } from "../functions/data-test-ids";
import { Button } from "./button";
import "./button-group.css";
function ButtonGroup(props) {
    const getButtons = () => {
        return props.Buttons.map((button, index) => {
            return (
                <Button
                    Style={props.Style}
                    key={index}
                    Visible={button.Visible}
                    Label={button.Label}
                    Title={button.Title}
                    Icon={button.Icon}
                    IconSpin={button.IconSpin}
                    Data={button.Data}
                    Disabled={button.Disabled}
                    MenuItems={button.MenuItems}
                    Class={button.Class}
                    DropDirection={button.DropDirection}
                    HideLabel={props.HideLabels === true || props.Collapsed === true}
                    HideIcon={props.HideIcons === true}
                    HideDropDownIndicator={button.HideDropDownIndicator === true || props.Collapsed === true}
                    HideMenuIcons={props.HideMenuIcons}
                    Danger={button.Danger}
                    DataTestId={getDataTestId(props.DataTestId, button.DataTestId || button.Label)}
                    OnClick={button.OnClick} />
            );
        });
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }

    if (!props.Buttons || props.Buttons.length === 0) {
        return null;
    }

    if (props.Collapsed) {
        return (
            <Button
                Icon={props.CollapsedIcon !== undefined ? props.CollapsedIcon : faEllipsisVertical}
                Label={props.CollapsedLabel}
                Title={props.CollapsedTitle}
                MenuItems={props.Buttons}
                DataTestId={getDataTestId(props.DataTestId, "collapsed")}/>
        );
    }
    return (
        <div className="eone-ui-button-group-container">
            {getButtons()}
        </div>
    );
}

export { ButtonGroup };
