import { faWindWarning } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const IconType = {
    FontAwesome: "fa",
    AppFont: "af"
};

function FontAwesome(props) {
    const getIcon = () => {
        if (props.Icon && typeof props.Icon === "object" && props.Icon.prefix && props.Icon.iconName && props.Icon.icon) {
            return props.Icon;
        }
        else {
            return null;
        }
    };

    const getStyle = () => {
        if (typeof props.IconStyle === "string") return undefined;
        return props.IconStyle;
    };

    if (props.Visible === false) { 
        return null;
    }

    const icon = getIcon();
    if (!icon || icon === "") { 
        return null;
    }
    else return (
        <FontAwesomeIcon
            icon={icon}
            style={getStyle()}
            className={props.ClassName}
            fixedWidth={props.FixedWidth}
            spin={props.Spin}
            flip={props.Flip}
            rotate={props.Rotate}
            size={props.Size}
            title={props.Title}
            color={props.Color} />
    );
}

function AppFont(props) {
    const getStyle = () => {
        if (props.IconStyle) {
            return props.IconStyle;
        }
        if (props.FontSize || props.Color) {
            const style = {};
            if (props.FontSize) {
                style.fontSize = props.FontSize;
            }
            if (props.Color) {
                style.color = props.Color;
            }
            return style;
        }
        return null;
    };

    const getClass = () => {
        if (!props.Icon) {
            return undefined;
        }
        let iconClass = `af af-${props.Icon}`;
        if (props.ClassName) {
            iconClass = `${iconClass} ${props.ClassName}`;
        }
        return iconClass;
    };

    return <i className={getClass()} style={getStyle()} aria-hidden="true" title={props.Title} />;
}

function Icon(props) {
    switch (props.Type) {
        case IconType.AppFont:
            return (
                <AppFont
                    Icon={props.Icon}
                    ClassName={props.ClassName}
                    Title={props.Title}
                    Color={props.Color}
                    IconStyle={props.IconStyle}/>
            );
        default:
            return (
                <FontAwesome
                    Icon={props.Icon}
                    IconStyle={props.IconStyle}
                    ClassName={props.ClassName}
                    FixedWidth={props.FixedWidth}
                    Spin={props.Spin}
                    Flip={props.Flip}
                    Rotate={props.Rotate}
                    Size={props.Size}
                    Title={props.Title}
                    Color={props.Color} />
            );
    }
}

export { FontAwesome, AppFont, Icon, IconType };