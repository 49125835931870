
export function getTextContent(value) {
    const linkBegin = value.indexOf('!');
    if (linkBegin === -1) return value;
    const linkEnd = value.indexOf(')');
    if (linkEnd === -1) return value;
    const linkText = value.slice(linkBegin + 2, linkEnd);
    const splitIndex = linkText.indexOf('(');
    const linkPart = linkText.slice(0, splitIndex - 1);
    const textPart = linkText.slice(splitIndex + 1, linkText.length);
    const internalLink = linkPart.startsWith("help.");
    if (internalLink) {
        return (
            <React.Fragment>
                {value.slice(0, linkBegin)}
                <span className="link-blue-green cursor" >
                    {textPart}
                </span>
                {value.slice(linkEnd + 1, value.length)}
            </React.Fragment >
        );
    }

    return (
        <React.Fragment>
            {value.slice(0, linkBegin)}
            <a href={linkPart} className="link-blue-green cursor" target="blank">
                {textPart}
            </a>
            {value.slice(linkEnd + 1, value.length)}
        </React.Fragment >
    );
}