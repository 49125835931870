import { HorizontalDragPanel } from "../../drag-handle/drag-panel";
import "./navigation-sidebar.css";

function NavigationSidebarHeader(props) {
    if (!props.Title) {
        return null;
    }
    
    return (
        <div className="eone-ui-navigation-sidebar-header-container">
            <div className="eone-ui-navigation-sidebar-header-label">{props.Title}</div>
            <div className="eone-ui-navigation-sidebar-header-buttons"></div>
        </div>
    );
}

function NavigationSidebar(props) {
    if (!props.Sidebar) {
        return null;
    }
    return (
        <div className={`eone-ui-navigation-sidebar-container`}>
            <HorizontalDragPanel InitialWidth={400} MinWidth={300} SetIsDragging={props.SetIsDragging}>
                <NavigationSidebarHeader Title={props.Sidebar.Title} />
                <div className="eone-ui-navigation-sidebar-body">
                    {props.Sidebar.Body}
                </div>
            </HorizontalDragPanel >
        </div>
    );
} 

export { NavigationSidebar };
