import React from "react";
import "./forms.css";

function FormLabel(props) {
    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    
    return (
        <div className="eone-ui-form-label-container">
            <label className="eone-ui-form-label">
                <span>{props.Label}</span>
            </label>
        </div>
    );
}

export {FormLabel};