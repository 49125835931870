export const ModalButtonType = {
    Primary: 1,
    Secondary: 2,
    Danger: 3,
    Link: 4,
    Processing: 5
};

export const ModalButtonAlignment = {
    Left: 1,
    Right: 2
}

export const ModalWidth = {
    Normal: 1,
    Narrow: 2,
    ExtraNarrow: 3,
    Wide: 4,
    ExtraWide: 5
};