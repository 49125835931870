import { CSSProperties, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { isNullOrUndefined } from "../functions/general";
import "./dropdown-panel.css";

export const DropdownPosition = {
    Above: 1,
    Below: 2,
    LeftOf: 3,
    RightOf: 4,
} as const;
export type DropdownPosition = typeof DropdownPosition[keyof typeof DropdownPosition];

export const DropdownDirection = {
    Up: 1,
    Down: 2,
} as const;
export type DropdownDirection = typeof DropdownDirection[keyof typeof DropdownDirection];

export const DropdownAlignment = {
    AlignLeft: 1,
    AlignRight: 2,
    AlignLeftAndRight: 3,
} as const;
export type DropdownAlignment = typeof DropdownAlignment[keyof typeof DropdownAlignment];

const OVERRIDE_PREFERENCE_THRESHOLD_WIDTH = 60;

const OVERRIDE_PREFERENCE_THRESHOLD_HEIGHT = 120;

const DEFAULT_VERTICAL_GAP = 0;

const DISTANCE_TO_SCREEN_EDGE_BUFFER = 10;

function getPanelPosition(
    preferedPosition: DropdownPosition | undefined,
    preferedDirection: DropdownDirection | undefined,
    roomAbove: number,
    roomBelow: number,
    roomRight: number,
    roomLeft: number,
    thresholdHeight?: number
):
    [
        DropdownPosition,
        DropdownDirection
    ] {


    const theThresholdHeight = thresholdHeight ?? OVERRIDE_PREFERENCE_THRESHOLD_HEIGHT;
    // default direction to down
    let actualDirection = preferedDirection ?? DropdownDirection.Down;

    // default position to above or below
    let actualPosition = preferedPosition ?? (actualDirection === DropdownDirection.Up ? DropdownPosition.Above : DropdownPosition.Below);

    // ensure position and direction are consistent with each other
    if (actualPosition === DropdownPosition.Above) {
        actualDirection = DropdownDirection.Up;
    }
    if (actualPosition === DropdownPosition.Below) {
        actualDirection = DropdownDirection.Down;
    }

    // swap position / direction if the preferred one doesn't have enough room 
    switch (actualPosition) {
        case DropdownPosition.Above:
            if (roomAbove < theThresholdHeight && roomAbove < roomBelow) {
                /* above is really small and smaller than below, swap directions */
                actualPosition = DropdownPosition.Below;
                actualDirection = DropdownDirection.Down;
            }
            break;
        case DropdownPosition.Below:
            if (roomBelow < theThresholdHeight && roomBelow < roomAbove) {
                /* below is really small and smaller than above, swap directions */
                actualPosition = DropdownPosition.Above;
                actualDirection = DropdownDirection.Up;
            }
            break;
        case DropdownPosition.LeftOf:
            if (roomLeft < theThresholdHeight && roomLeft < roomRight) {
                /* to the left is really small and smaller than to the right, swap directions */
                actualPosition = DropdownPosition.RightOf;
            }
            switch (actualDirection) {
                case DropdownDirection.Up:
                    if (roomAbove < theThresholdHeight && roomAbove < roomBelow) {
                        /* above is really small and smaller than below, swap directions */
                        actualDirection = DropdownDirection.Down;
                    }
                    break;
                case DropdownDirection.Down:
                    if (roomBelow < theThresholdHeight && roomBelow < roomAbove) {
                        /* below is really small and smaller than above, swap directions */
                        actualDirection = DropdownDirection.Up;
                    }
                    break;
            }
            break;
        case DropdownPosition.RightOf:
            if (roomRight < theThresholdHeight && roomRight < roomLeft) {
                /* to the right is really small and smaller than to the left, swap directions */
                actualPosition = DropdownPosition.LeftOf;
            }
            switch (actualDirection) {
                case DropdownDirection.Up:
                    if (roomAbove < theThresholdHeight && roomAbove < roomBelow) {
                        /* above is really small and smaller than below, swap directions */
                        actualDirection = DropdownDirection.Down;
                    }
                    break;
                case DropdownDirection.Down:
                    if (roomBelow < theThresholdHeight && roomBelow < roomAbove) {
                        /* below is really small and smaller than above, swap directions */
                        actualDirection = DropdownDirection.Up;
                    }
                    break;
            }
            break;
    }

    return [actualPosition, actualDirection];
}



type UseDropdownPanel<T> = {
    open: boolean,
    close: () => void;
    setOpen: (value: boolean) => void;
    toggleOpen: () => void;
    animating: boolean;
    triggerRef: React.RefObject<T> | null
    style: CSSProperties;
}

export const useDropdownPanel = <T extends HTMLDivElement>(
    position: DropdownPosition,
    direction: DropdownDirection,
    alignment: DropdownAlignment,
    verticalGap: number,
    maxHeight: number,
    thresholdHeight?: number,
): UseDropdownPanel<T> => {
    const [open, _setOpen] = useState(false);

    const [animating, setAnimating] = useState(false);

    const setOpen = (value: boolean) => {
        setAnimating(true);
        setTimeout(() => setAnimating(false), 200);
        _setOpen(value);
    }

    const triggerRef = useRef<T>(null);

    const toggleOpen = () => {
        setOpen(!open);
    }

    const close = () => {
        setOpen(false);
    }

    const getClampedMaxHeight = (calculatedMaxHeight: number) => {
        const clampedMaxHeight = isNullOrUndefined(maxHeight) ? calculatedMaxHeight : Math.min(maxHeight, calculatedMaxHeight);
        return `${clampedMaxHeight}px`;
    };

    const getPanelAttributes = (): {
        position: DropdownPosition;
        direction: DropdownDirection;
        style: CSSProperties;
    } => {
        if (!open || !triggerRef.current) {
            return {
                position: DropdownPosition.Below,
                direction: DropdownDirection.Down,
                style: {}
            };
        }

        const triggerRect = triggerRef.current.getBoundingClientRect();
        const isLeftRight = position === DropdownPosition.LeftOf || position === DropdownPosition.RightOf;

        const roomAbove = triggerRect.top;
        const roomBelow = window.innerHeight - triggerRect.bottom;
        const roomLeft = triggerRect.right - (isLeftRight ? triggerRect.width : 0);
        const roomRight = window.innerWidth - triggerRect.left - (isLeftRight ? triggerRect.width : 0);

        const [actualPosition, actualDirection] = getPanelPosition(position, direction, roomAbove, roomBelow, roomRight, roomLeft, thresholdHeight);

        const actualVerticalGap = isNullOrUndefined(verticalGap) ? DEFAULT_VERTICAL_GAP : verticalGap;

        const style: CSSProperties = {};

        switch (actualDirection) {
            case DropdownDirection.Up:
                if (actualPosition === DropdownPosition.LeftOf || actualPosition === DropdownPosition.RightOf) {
                    // align bottom of menu with bottom of button, no gap.
                    style.bottom = `${window.innerHeight - triggerRect.bottom}px`;
                    style.maxHeight = getClampedMaxHeight(roomAbove + triggerRect.height - DISTANCE_TO_SCREEN_EDGE_BUFFER);
                } else {
                    // align bottom of menu with top of button, add a gap
                    style.bottom = `${window.innerHeight - triggerRect.top + verticalGap}px`;
                    style.maxHeight = getClampedMaxHeight(roomAbove - actualVerticalGap - DISTANCE_TO_SCREEN_EDGE_BUFFER);
                }
                break;
            case DropdownDirection.Down:
                if (actualPosition === DropdownPosition.LeftOf || actualPosition === DropdownPosition.RightOf) {
                    // align top of menu with top of button, no gap.
                    style.top = `${triggerRect.top}px`;
                    style.maxHeight = getClampedMaxHeight(roomBelow + triggerRect.height - DISTANCE_TO_SCREEN_EDGE_BUFFER);
                } else {
                    // align top of menu with bottom of button, add a gap.
                    style.top = `${triggerRect.bottom + actualVerticalGap}px`;
                    style.maxHeight = getClampedMaxHeight(roomBelow - actualVerticalGap - DISTANCE_TO_SCREEN_EDGE_BUFFER);
                }
                break;
        }

        let actualAlignment = alignment;
        switch (alignment) {
            case DropdownAlignment.AlignLeft:
            case DropdownAlignment.AlignRight:
                actualAlignment = (roomLeft > roomRight) ? DropdownAlignment.AlignRight : DropdownAlignment.AlignLeft;
                break;
        }

        switch (actualPosition) {
            case DropdownPosition.Above:
            case DropdownPosition.Below:
                switch (actualAlignment) {
                    case DropdownAlignment.AlignLeft:
                        style.left = `${triggerRect.left}px`;
                        style.maxWidth = `${triggerRect.width + roomRight - DISTANCE_TO_SCREEN_EDGE_BUFFER}px`;
                        break;
                    case DropdownAlignment.AlignRight:
                        style.right = `${window.innerWidth - triggerRect.right}px`;
                        style.maxWidth = `${triggerRect.width + roomLeft - DISTANCE_TO_SCREEN_EDGE_BUFFER}px`;
                        break;
                    case DropdownAlignment.AlignLeftAndRight:
                        style.left = `${triggerRect.left}px`;
                        style.right = `${window.innerWidth - triggerRect.right}px`;
                        style.width = `${triggerRect.width}px`;
                        break;
                }
                break;
            case DropdownPosition.LeftOf:
                style.right = `${window.innerWidth - triggerRect.left}px`;
                style.maxWidth = `${roomLeft}px`;
                style.width = `auto`;
                break;
            case DropdownPosition.RightOf:
                style.left = `${triggerRect.right}px`;
                style.maxWidth = `${roomRight}px`;
                style.width = `auto`;
                break;
        }

        return {
            style,
            position: actualPosition,
            direction: actualDirection,
        }
    }

    return {
        open,
        close,
        setOpen,
        toggleOpen,
        triggerRef,
        animating,
        ...getPanelAttributes(),
    };
}

type DropdownPanelProps = {
    Style: CSSProperties;
    Open: boolean;
    Close: () => void;
    DropdownPanelRef?: React.RefObject<HTMLDivElement> | null;
    OnMouseEnter?: () => void;
    OnMouseLeave?: () => void;
};
function DropdownPanel(props: React.PropsWithChildren<DropdownPanelProps>) {
    useEffect(() => {
        if (props.Open) {
            window.addEventListener("resize", props.Close);
        }
        return () => window.removeEventListener("resize", props.Close);
    }, [props.Open]);

    return ReactDOM.createPortal(
        <div
            ref={props.DropdownPanelRef}
            style={props.Style}
            className={`eone-ui-dropdown-panel ${props.Open ? "open" : "closed"}`}
            onMouseEnter={props.OnMouseEnter}
            onMouseLeave={props.OnMouseLeave} >
            {props.children}
        </div>,
        document.body
    );
}

export { DropdownPanel };
