import React, { Children } from "react";
import {Panel} from "./panel";
import {useAutoAnimate} from "@formkit/auto-animate/react";

function FormPanelBody(props) {
    const [body, enableAnimations] = useAutoAnimate();
    
    if (props.Loading) {
        const columns = Children.toArray(props.children);
        return columns.map((column, index) => {
            return (
                React.cloneElement(column, { key: index, Skeleton: true })
            );
        });
    }

    if (props.Animate) {
        return (
            <div ref={body}>
                {props.children}
            </div>
        );
    }
    
    return (
        <div>
            {props.children}
        </div>
    );
}

function FormPanel(props) {
    const collapseHandler = (value) => {
        if (typeof props.OnCollapse === "function") {
            props.OnCollapse(value);
        }
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    
    return (
        <Panel
            Icon={props.Icon}
            IconType={props.IconType}
            IconStyle={props.IconStyle}
            Title={props.Title}
            Explanation={props.Explanation}
            ExplanationString={props.ExplanationString}
            BodyClass="eone-ui-panel-form-content"
            HeaderButtons={props.HeaderButtons}
            Visible={props.Visible}
            BodyHeight={props.BodyHeight}
            Style={props.Style}
            TooltipInfo={props.TooltipInfo}
            Collapsible={props.Collapsible}
            Collapsed={props.Collapsed}
            OnCollapse={collapseHandler}
            DataTestId={props.DataTestId}>
            
            <FormPanelBody 
                Loading={props.Loading}
                Animate={props.Animate}>
                {props.children}
            </FormPanelBody>

        </Panel>
    );
}

export {FormPanel};