
import { FormSpacer } from "eone-ui/forms/form-spacer";
import { FormString } from "eone-ui/forms/form-string";
import { FormPanel } from "eone-ui/panels/form-panel";
import { data_get } from "popdock-shared/Functions/data-access";
import React, { useEffect, useState } from "react";
import { getJSON } from "./get-json";
import { Navigation } from "eone-ui/navigation/navigation";


function ShowWidgetIds(props) {

    const [widgetIds, setWidgetIds] = useState([]);
    const [token, setToken] = useState();
    const [domain, setDomain] = useState();

    useEffect(() => {
        const load = async () => {
            const response = await data_get(`/AddConnector/GetWidgetIds`);
            setWidgetIds(response.WidgetIds);
            setToken(response.Token);
            setDomain(response.Domain);
            props.HideHelp();
        }
        load();
    }, []);

    return (
        <Navigation ItemsLabel={"Steps"} Items={props.NavigationItems} Selected={"complete-install"}>
            <FormSpacer />
            <FormSpacer />
            <FormPanel>
                <div>
                    <a href="https://login.popdock.com" target="_blank">Login</a> to Popdock to configure your widgets.
                </div>
            </FormPanel>
            <FormPanel>
                <FormString Label={"Domain"} Value={domain} ReadOnly ShowCopyButton />
            </FormPanel>
            {widgetIds.map(id => {
                return (
                    <FormPanel>
                        {id.Widgets.map(widget => {
                            return <FormString Label={widget.Name} Value={widget.WidgetId} ReadOnly ShowCopyButton />
                        })}
                    </FormPanel>
                );
            })
            }
            <FormPanel>
                <FormString Label={"Token"} Value={token} ReadOnly ShowCopyButton />
            </FormPanel>
        </Navigation>
    );
}

export { ShowWidgetIds };
