import React from "react";
import "./forms.css";
import {Tooltip} from "../tooltip/tooltip";

function FormCheckbox(props) {
    const changeHandler = (e) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(props.Data);
        }
        if (typeof props.OnToggle === "function") {
            props.OnToggle(e.target.checked);
        }
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    
    if (props.Skeleton) {
        return (
            <div className={props.Inline ? "eone-ui-form-checkbox-inline-container" : "eone-ui-form-checkbox-container"} title={props.Title}>
                <label className="eone-ui-form-checkbox">
                    <input type="checkbox" className="eone-ui-form-checkbox-skeleton" disabled data-testid={props.DataTestId}/>
                    <span className="eone-ui-form-checkbox-label">{props.Label}</span>
                </label>
            </div>
        );
    }
    
    return (
        <div className={props.Inline ? "eone-ui-form-checkbox-inline-container" : "eone-ui-form-checkbox-container"} title={props.Title}>
            <label className="eone-ui-form-checkbox">
                <input type="checkbox" onChange={changeHandler} checked={props.Value} disabled={props.ReadOnly} data-testid={props.DataTestId}/>
                <span className="eone-ui-form-checkbox-label">{props.Label}</span>
            </label>
            <Tooltip
                Title={props.TooltipTitle ? props.TooltipTitle : props.Label}
                Tooltip={props.Tooltip}
                TooltipTitle={props.TooltipTitle}
                TooltipWidth={props.TooltipWidth}
                DataTestId={props.DataTestId}/>
        </div>
    );
}

export {FormCheckbox};