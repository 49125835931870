import { faAngleDown, faAngleUp, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useRef, useEffect, useImperativeHandle, useState } from 'react';
import { DropdownAlignment, DropdownDirection, DropdownPanel, DropdownPosition, useDropdownPanel } from "../dropdown-panel/dropdown-panel";
import { isNullOrUndefined } from "../functions/general";
import { Icon } from "../icons/icons";
import "./button.css";

const ButtonMenuDropDirection = {
    UpLeft: 1,
    UpRight: 2,
    DownLeft: 3,
    DownRight: 4,
    Left: 5,
    Right: 6,
    Up: 7,
    Down: 8
};
type ButtonMenuDropDirection = typeof ButtonMenuDropDirection[keyof typeof ButtonMenuDropDirection];

function ButtonMenuDivider() {
    return (
        <div className="eone-ui-button-drop-down-menu-divider" />
    );
}

type ButtonMenuItemIconProps = {
    Icon?: IconDefinition;
    IconType?: string;
    Hidden?: boolean;
}
function ButtonMenuItemIcon(props: ButtonMenuItemIconProps) {
    if (!props.Icon || props.Hidden) {
        return null;
    }

    return (
        <div className="eone-ui-button-drop-down-menu-item-icon">
            <Icon Icon={props.Icon} Type={props.IconType} FixedWidth />
        </div>
    );
}
interface ButtonDropDownIndicatorProps {
    Visible?: boolean;
    Hidden?: boolean;
    DropDirection?: ButtonMenuDropDirection;
}

function ButtonDropDownIndicator(props: ButtonDropDownIndicatorProps) {
    if (!props.Visible || props.Hidden) {
        return null;
    }
    const icon = props.DropDirection === DropdownDirection.Up ? faAngleUp : faAngleDown;
    return (
        <div className="eone-ui-button-drop-down-indicator">
            <FontAwesomeIcon icon={icon} fixedWidth />
        </div>
    );
}
interface ButtonLabelProps {
    Label?: string;
    Hidden?: boolean;
    IconHidden?: boolean;
    ClassName?: string;
}
function ButtonLabel(props: ButtonLabelProps) {
    const getClassName = () => {
        const className = props.IconHidden ? "eone-ui-button-label-no-icon" : "eone-ui-button-label";
        if (props.ClassName) {
            return `${className} ${props.ClassName}`;
        }
        return className;
    }

    if (!props.Label || props.Hidden) {
        return null;
    }
    return (
        <div className={getClassName()}>{props.Label}</div>
    );
}

interface ButtonIconProps {
    Icon?: IconDefinition;
    IconType?: string;
    Hidden?: boolean;
    LabelHidden?: boolean;
    Spin?: boolean;
}
function ButtonIcon(props: ButtonIconProps) {
    if (!props.Icon || props.Hidden) {
        return null;
    }

    return (
        <div className={props.LabelHidden ? "eone-ui-button-icon eone-ui-button-icon-only" : "eone-ui-button-icon"}>
            <Icon Icon={props.Icon} Type={props.IconType} Spin={props.Spin} FixedWidth />
        </div>
    );
}

interface ButtonMenuItem {
    Label: string;
    Icon?: IconDefinition;
    IconType?: string;
    Data?: any;
    Disabled?: boolean;
    MenuItems?: ButtonMenuItem[];
    DataTestId?: string;
    Danger?: boolean;
    OnClick?: (data?: any) => void;
    Visible?: boolean;
    Divider?: boolean;
}

interface ButtonMenuItemProps {
    Icon?: IconDefinition;
    IconType?: string;
    Hidden?: boolean;
    Label: string;
    Title?: string;
    Danger?: boolean;
    Disabled?: boolean;
    DisabledMessage?: string;
    HideIcon?: boolean;
    HideDropDownIndicator?: boolean;
    DropDirection?: DropdownDirection;
    MenuItems?: ButtonMenuItem[];
    OnClick?: (menuData?: any, buttonData?: any) => void;
    CloseMenu: () => void;
    Data?: any;
    DataTestId?: string;
    DropdownSubPanelRef?: React.RefObject<HTMLDivElement>;
    HideMenuIcons?: boolean;
    ButtonData: any;
}
function ButtonMenuItem(props: ButtonMenuItemProps) {
    const {
        open,
        close,
        toggleOpen,
        animating,
        triggerRef,
        style,
    } = useDropdownPanel(DropdownPosition.LeftOf, DropdownDirection.Down, DropdownAlignment.AlignRight, 4, window.innerHeight * 0.8);

    const clickHandler = () => {
        if (props.MenuItems && props.MenuItems.length > 0) {
            toggleOpen();
        }
        else {
            props.OnClick?.(props.Data, props.ButtonData);
            props.CloseMenu();
        }
    }

    const getMenuItemClass = () => {
        let className = "eone-ui-button-drop-down-menu-item";
        if (props.Danger) {
            className += " danger"
        }
        return className;
    }

    if (props.Disabled) {
        return (
            <a className="eone-ui-button-drop-down-menu-item-disabled" data-testid={props.DataTestId} title={props.DisabledMessage || "This feature requires a subscription that your account does not have enabled"}>
                <ButtonMenuItemIcon Icon={props.Icon} IconType={props.IconType} Hidden={props.HideIcon} />
                <div className="eone-ui-button-drop-down-menu-item-label">{props.Label}</div>
            </a>
        );
    }

    return (
        <span className="eone-ui-button-container" ref={triggerRef} >
            <a className={getMenuItemClass()} data-testid={props.DataTestId} title={props.Title || props.Label} onClick={clickHandler}>
                <span style={{ display: "flex" }}>
                    <ButtonMenuItemIcon Icon={props.Icon} IconType={props.IconType} Hidden={props.HideIcon} />
                    <div className="eone-ui-button-drop-down-menu-item-label">{props.Label}</div>
                </span>
                <ButtonDropDownIndicator Hidden={props.HideDropDownIndicator} DropDirection={props.DropDirection} Visible={props.MenuItems && props.MenuItems.length > 0} />
            </a>
            <DropdownPanel Style={style} Open={open} Close={close} DropdownPanelRef={open ? props.DropdownSubPanelRef : null}>
                <ButtonMenu
                    Visible={open}
                    Animating={animating}
                    HideMenuIcons={props.HideMenuIcons}
                    MenuItems={props.MenuItems}
                    OnClick={props.OnClick}
                    CloseMenu={close}
                    DataTestId={props.DataTestId} />
            </DropdownPanel >
        </span>
    );
}



interface ButtonMenuProps {
    Visible: boolean;
    HideMenuIcons?: boolean;
    MenuItems?: ButtonMenuItem[];
    OnClick?: (data?: any) => void;
    CloseMenu: () => void;
    Animating: boolean;
    Style?: React.CSSProperties;
    DropdownSubPanelRef?: React.RefObject<HTMLDivElement>;
    DataTestId?: string;
    ButtonData?: any
    OnMouseEnter?: () => void;
    OnMouseLeave?: () => void;
}

function ButtonMenu(props: ButtonMenuProps) {
    const getItems = () => {
        return props.MenuItems?.filter(menuItem => menuItem.Visible !== false).map((menuItem, index) => {
            if (menuItem.Divider) {
                return (
                    <ButtonMenuDivider key={index} />
                );
            }
            return (
                <ButtonMenuItem
                    key={index}
                    DropdownSubPanelRef={props.DropdownSubPanelRef}
                    Label={menuItem.Label}
                    Icon={menuItem.Icon}
                    IconType={menuItem.IconType}
                    Data={menuItem.Data}
                    Disabled={menuItem.Disabled}
                    MenuItems={menuItem.MenuItems}
                    DataTestId={menuItem.DataTestId}
                    Danger={menuItem.Danger}
                    HideIcon={props.HideMenuIcons}
                    OnClick={menuItem.OnClick || props.OnClick}
                    ButtonData={props.ButtonData}
                    CloseMenu={props.CloseMenu} />
            );
        });
    };

    if (!props.Visible) {
        return null;
    }

    return (
        <span
            className={`eone-ui-button-drop-down-menu ${props.Animating ? "animating" : ""}`}
            style={props.Style}
            onMouseEnter={props.OnMouseEnter}
            onMouseLeave={props.OnMouseLeave} >
            <div className="eone-ui-button-drop-down-menu-container">
                {getItems()}
            </div>
        </span>
    );
}


export type ButtonProps = {
    Icon?: IconDefinition;
    IconType?: string;
    IconSpin?: boolean;
    Label?: string;
    LabelHidden?: boolean;
    Title?: string;
    Danger?: boolean;
    Disabled?: boolean;
    HideIcon?: boolean;
    HideLabel?: boolean;
    HideDropDownIndicator?: boolean;
    HideMenuIcons?: boolean;
    DropDirection?: ButtonMenuDropDirection;
    MenuItems?: ButtonMenuItem[];
    OnClick?: (data?: any) => void;
    Data?: any;
    DataTestId?: string;
    Visible?: boolean;
    Class?: string;
    Style?: React.CSSProperties;
    AccessKey?: string;
    LabelClass?: string;
}

type ButtonHandle = {
    open: boolean;
    hovered: boolean;
};


const Button = forwardRef<ButtonHandle, ButtonProps>((props, ref) => {
    const [hovered, setHovered] = useState(false);
    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => setHovered(false);


    const getDropdownDirection = () => {
        switch (props.DropDirection) {
            case ButtonMenuDropDirection.UpLeft:
            case ButtonMenuDropDirection.UpRight:
            case ButtonMenuDropDirection.Up:
                return DropdownDirection.Up;
            default:
                return DropdownDirection.Down;
        }
    }

    const getDropdownPosition = () => {
        switch (props.DropDirection) {
            case ButtonMenuDropDirection.UpLeft:
                return DropdownPosition.Above;
            case ButtonMenuDropDirection.DownLeft:
                return DropdownPosition.Below;
            case ButtonMenuDropDirection.Left:
                return DropdownPosition.LeftOf;
            case ButtonMenuDropDirection.UpRight:
                return DropdownPosition.Above;
            case ButtonMenuDropDirection.DownRight:
                return DropdownPosition.Below;
            case ButtonMenuDropDirection.Right:
                return DropdownPosition.RightOf;
            case ButtonMenuDropDirection.Up:
                return DropdownPosition.Above;
            case ButtonMenuDropDirection.Down:
                return DropdownPosition.Below;
            default:
                return DropdownPosition.Below;
        }
    }

    const getDropdownAlignment = () => {
        switch (props.DropDirection) {
            case ButtonMenuDropDirection.UpLeft:
            case ButtonMenuDropDirection.DownLeft:
            case ButtonMenuDropDirection.Left:
                return DropdownAlignment.AlignLeft;
            default:
                return DropdownAlignment.AlignRight;
        }
    }

    const {
        open,
        setOpen,
        close,
        toggleOpen,
        animating,
        triggerRef,
        style,
    } = useDropdownPanel(getDropdownPosition(), getDropdownDirection(), getDropdownAlignment(), 4, 500);

    useImperativeHandle(ref, () => ({
        open,
        hovered
    }));

    const dropdownPanelRef = useRef<HTMLDivElement>(null);
    const dropdownSubPanelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const outsideTrigger = triggerRef?.current && !triggerRef.current.contains(e.target as Node);
            const outsidePanel = dropdownPanelRef.current && !dropdownPanelRef.current.contains(e.target as Node);
            const outsideSubPanel = !dropdownSubPanelRef.current || (dropdownSubPanelRef.current && !dropdownSubPanelRef.current.contains(e.target as Node));
            if (outsideTrigger && outsidePanel && outsideSubPanel) {
                close();
            }
        };
        if (open) {
            window.addEventListener("mousedown", handleClickOutside);
        }
        return () => window.removeEventListener("mousedown", handleClickOutside);
    }, [open]);

    const onClick = () => {
        if (props.MenuItems && props.MenuItems.length > 0) {
            toggleOpen();
        }
        else {
            props.OnClick?.(props.Data);
        }
    }

    const getTitle = () => {
        if (!isNullOrUndefined(props.Title)) return props.Title;
        return props.Label;
    }

    const getContainerClass = () => {
        if (props.Class) {
            return `eone-ui-button-container ${props.Class}`;
        }
        return "eone-ui-button-container";
    }

    const getButtonClass = () => {
        let className = "eone-ui-button";
        if (props.Danger) {
            className += " danger"
        }
        return className;
    }

    if (props.Visible !== undefined && !props.Visible) {
        if (open) {
            close();
        }
        return null;
    }

    if (props.Disabled) {
        return (
            <div className={getContainerClass()} style={props.Style} >
                <div className="eone-ui-button-disabled" title={getTitle()} data-testid={props.DataTestId}>
                    <ButtonIcon Icon={props.Icon} Hidden={props.HideIcon} IconType={props.IconType} Spin={props.IconSpin} LabelHidden={props.LabelHidden || !props.Label} />
                    <ButtonLabel ClassName={props.LabelClass} Label={props.Label} Hidden={props.HideLabel} IconHidden={props.HideIcon || !props.Icon} />
                </div>
            </div>

        );
    }

    return (
        <div
            className={getContainerClass()}
            style={props.Style}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            ref={triggerRef}>
            <a className={getButtonClass()} title={getTitle()} data-testid={props.DataTestId} onClick={onClick} style={props.Style} accessKey={props.AccessKey}>
                <ButtonIcon Icon={props.Icon} Hidden={props.HideIcon} IconType={props.IconType} Spin={props.IconSpin} LabelHidden={props.LabelHidden || !props.Label} />
                <ButtonLabel ClassName={props.LabelClass} Label={props.Label} Hidden={props.HideLabel} IconHidden={props.HideIcon || !props.Icon} />
                <ButtonDropDownIndicator Hidden={props.HideDropDownIndicator} DropDirection={props.DropDirection} Visible={props.MenuItems && props.MenuItems.length > 0} />
            </a>
            <DropdownPanel Style={style} Open={open} Close={close} DropdownPanelRef={dropdownPanelRef} OnMouseEnter={handleMouseEnter} OnMouseLeave={handleMouseLeave}>
                <ButtonMenu
                    Animating={animating}
                    Visible={open}
                    DropdownSubPanelRef={dropdownSubPanelRef}
                    HideMenuIcons={props.HideMenuIcons}
                    MenuItems={props.MenuItems}
                    OnClick={props.OnClick}
                    ButtonData={props.Data}
                    CloseMenu={close}
                    DataTestId={props.DataTestId}
                    OnMouseEnter={handleMouseEnter}
                    OnMouseLeave={handleMouseLeave} />
            </DropdownPanel>
        </div>
    );
});

Button.displayName = "Button";


export { Button, ButtonMenuDropDirection };
