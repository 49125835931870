import { faAngleDown, faAngleRight, faCheck, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { DropdownAlignment, DropdownDirection, DropdownPanel, DropdownPosition, useDropdownPanel } from "../dropdown-panel/dropdown-panel";
import { getDataTestId } from "../functions/data-test-ids";
import { InputString } from "../inputs/input-string";
import "./select.css";

const SELECT_SEARCH_THRESHOLD = 10;
const SELECT_SUBITEM_SEPARATOR = " / ";

function SelectOptionColor(props) {
    if (!props.Color) {
        return null;
    }

    return (
        <div className="eone-ui-select-item-color" style={{ backgroundColor: `#${props.Color}` }} />
    );
}

function SelectOptionIcon(props) {
    if (!props.Icon) {
        return null;
    }

    return (
        <span className="eone-ui-select-item-icon">
            <FontAwesomeIcon icon={props.Icon} />
        </span>
    );
}

function SelectOptionDescription(props) {
    if (!props.Description) {
        return null;
    }

    return (
        <span className="eone-ui-select-item-description">{props.Description}</span>
    );
}

function SelectOptionCheck(props) {
    if (props.Checked === undefined || !props.Checked) {
        return null;
    }

    return (
        <span className="eone-ui-select-item-check">
            <FontAwesomeIcon icon={faCheck} />
        </span>
    );
}

function SelectOptionMenuIndicator(props) {
    if (!props.Visible) {
        return null;
    }

    return (
        <span className="eone-ui-select-item-menu-indicator">
            <FontAwesomeIcon icon={faAngleRight} />
        </span>
    );
}

function SelectOptionSearch(props) { 
    return (
        <InputString
            Visible={props.Visible}
            ContainerClass="eone-ui-select-menu-search-container"
            Icon={faSearch}
            Placeholder="Search"
            Value={props.SearchValue}
            OnChange={props.ChangeSearch}
            DataTestId={getDataTestId(props.DataTestId, "search")} />
    );
}

function SelectOption(props) {
    const {
        open,
        close,
        setOpen,
        toggleOpen,
        animating,
        triggerRef,
        style,
        position,
        direction,
    } = useDropdownPanel(
        DropdownPosition.RightOf,
        props.DropDirection,
        null,
        0,
        window.innerHeight * 0.5,
    );

    const onMouseEnter = () => {
        setOpen(true);
    }

    const onMouseLeave = () => {
        setOpen(false);
    }

    const onClick = () => {
        if (typeof props.OnChange === "function") {
            props.OnChange(props.Key, props.Data);
        }
    };

    const showMenu = () => { 
        return open && props.Options && props.Options.length > 0;
    }

    if (props.Value === "-") {
        return (
            <div className="eone-ui-select-separator" />
        );
    }

    if (props.Value && props.Value.startsWith("---") && props.Value.endsWith("---")) {
        return (
            <div className="eone-ui-select-header">{props.Value.slice(3, -3)}</div>
        );
    }

    const dataTestId = getDataTestId(props.DataTestId, "option", props.Key);
    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <a
                ref={triggerRef}
                className="eone-ui-select-item"
                style={props.Style}
                tabIndex={0}
                role="option"
                onClick={onClick}
                title={props.Title || props.Value} data-testid={dataTestId}>
                <div className="eone-ui-select-value-container">
                    <SelectOptionColor Color={props.Color} />
                    <SelectOptionIcon Icon={props.Icon} />
                    <span className="eone-ui-select-item-value">{props.Value}</span>
                </div>
                <SelectOptionDescription Description={props.Description} />
                <SelectOptionCheck Checked={props.Checked} />
                <SelectOptionMenuIndicator Visible={props.Options && props.Options.length > 0} />
            </a>
            <DropdownPanel DropdownPanelRef={open ? props.DropdownSubPanelRef : null} Style={style} Open={showMenu()} Close={close} >
                <SelectMenu
                    Open={showMenu()}
                    Animating={animating}
                    MultiSelect={props.MultiSelect}
                    Value={props.Value}
                    DropDirection={direction}
                    Options={props.Options}
                    DropdownPosition={position}
                    Search={false}
                    SearchValue={props.SearchValue}
                    OnChange={props.OnChange}
                    DataTestId={props.DataTestId} />
            </DropdownPanel>
        </div>
    );
}

function SelectMenu(props) {
    const showOption = (option, index) => {
        if (!props.SearchValue || props.SearchValue === "") return true;
        const description = props.OptionDescriptions ? props.OptionDescriptions[index] : "";
        return option.Value.toLowerCase().includes(props.SearchValue) || description.toLowerCase().includes(props.SearchValue);
    };
    const getOptions = () => {
        return props.Options?.map((option, index) => {
            if (showOption(option, index)) {
                return (
                    <SelectOption
                        key={index}
                        Index={index}
                        DropdownSubPanelRef={props.DropdownSubPanelRef}
                        Key={option.Key}
                        Value={option.Value}
                        Icon={option.Icon}
                        Color={option.Color}
                        Style={option.Style}
                        Title={option.Title}
                        Options={option.Options}
                        Description={option.Description}
                        Data={option.Data}
                        MultiSelect={props.MultiSelect}
                        Checked={props.MultiSelect && Array.isArray(props.Value) && props.Value.includes(option.Key)}
                        OnChange={props.OnChange}
                        DropDirection={props.DropDirection}
                        DataTestId={props.DataTestId} />
                );
            }

            return null;
        });
    };

    const getClass = () => { 
        let className = "eone-ui-select-menu";
        switch (props.DropdownPosition) {
            case DropdownPosition.Above:
                className = `${className} eone-ui-select-menu-place-up`;
                break;
            case DropdownPosition.Below:
                className = `${className} eone-ui-select-menu-place-down`;
                break;
            case DropdownPosition.LeftOf:
                className = `${className} eone-ui-select-menu-place-left`;
                break;
            case DropdownPosition.RightOf:
                className = `${className} eone-ui-select-menu-place-right`;
                break;
        }
        switch (props.DropDirection) {
            case DropdownDirection.Up:
                className = `${className} eone-ui-select-menu-drop-up`;
                break;
            case DropdownDirection.Down:
                className = `${className} eone-ui-select-menu-drop-down`;
                break;
        }
        if (props.Animating) { 
            className = `${className} animating`;
        }
        return className;
    }

    const getContainerClass = () => { 
        let className = "eone-ui-select-item-container";
        if (props.Animating) {
            className = `${className} animating`;
        }
        return className;
    }


    if (!props.Open) {
        return null;
    }
    return (
        <div className={getClass()} style={props.Style}>
            <SelectOptionSearch
                Visible={props.Search === true}
                SearchValue={props.SearchValue}
                ChangeSearch={props.ChangeSearch}
                DataTestId={props.DataTestId} />
            <div className={getContainerClass()}>
                {getOptions()}
            </div>
        </div>
    );
}

function Select(props) {
    const [searchValue, setSearchValue] = useState("");
    const [hasFocus, setHasFocus] = useState(false);

    const onUpdate = () => { 
        if (typeof props.OnBlur === "function" && props.MultiSelect) {
            props.OnBlur(props.Value);
        }
    }

    const {
        open,
        close,
        setOpen,
        toggleOpen,
        animating,
        triggerRef,
        style,
        position,
        direction,
    } = useDropdownPanel(
        DropdownPosition.Below,
        DropdownDirection.Down,
        DropdownAlignment.AlignLeftAndRight,
        1,
        window.innerHeight * 0.33,
        Math.min((Math.max(props.MenuItems?.length ?? 0, 10) + 1 ) * 30, 120)
    );

    const closeMenu = () => { 
        close();
        onUpdate();
    }

    const toggleMenu = () => { 
        if (open) {
            onUpdate();
        }
        toggleOpen();
    }

    const dropdownPanelRef = useRef();
    const dropdownSubPanelRef = useRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            const outsideTrigger = triggerRef.current && !triggerRef.current.contains(e.target);
            const outsidePanel = dropdownPanelRef.current && !dropdownPanelRef.current.contains(e.target);
            const outsideSubPanel = !dropdownSubPanelRef.current || (dropdownSubPanelRef.current && !dropdownSubPanelRef.current.contains(e.target));
            if (outsideTrigger && outsidePanel && outsideSubPanel) {
                closeMenu();
            }
        };
        if (open) {
            window.addEventListener("mousedown", handleClickOutside);
        }
        return () => window.removeEventListener("mousedown", handleClickOutside);
    }, [open, closeMenu]);


    const search = (value) => {
        setSearchValue(value?.toLowerCase() ?? "");
    };

    const setFocus = () => {
        setHasFocus(true);
    };

    const clearFocus = () => {
        setHasFocus(false);
    };

    const getOptionFirstChars = () => {
        let optionFirstChars = {};
        for (let i = 0, len = props.Options.length; i < len; i++) {
            if (props.Options[i].Key) {
                const firstChar = props.Options[i].Value.substring(0, 1).toLowerCase();
                if (firstChar && firstChar !== "-" && !(firstChar in optionFirstChars)) {
                    optionFirstChars[firstChar] = props.Options[i].Key;
                }
            }
        }
        return optionFirstChars;
    };

    const handleKeyDown = (event) => {
        if (event.code === "Escape" || event.code === "Tab") {
            setOpen(false);
            onUpdate();
        }
        if (event.code === "ArrowDown" && !open) {
            setOpen(true);
        }
        if (event.code === "ArrowUp" && open) {
            closeMenu();
        }
        if (!props.MultiSelect) {
            const optionFirstChars = getOptionFirstChars();
            const key = event.key.toLowerCase();
            if (key in optionFirstChars) {
                if (typeof props.OnChange === "function") {
                    props.OnChange(optionFirstChars[key]);
                }
            }
        }
    };

    const changeHandler = (value, data) => {
        if (props.MultiSelect) {
            if (typeof props.OnChange === "function") {
                let newValue = props.Value ? [...props.Value] : [];
                if (newValue.includes(value)) {
                    newValue = newValue.filter(item => item !== value);
                } else {
                    newValue.push(value);
                }
                props.OnChange(newValue, data);
            }
        } else {
            if (typeof props.OnChange === "function") {
                props.OnChange(value, data);
            }
            setOpen(false);
        }
    };

    const getSelectedOption = () => {
        if (props.MultiSelect || !props.Options) { 
            return [null, null];
        }
        for (let i = 0, len = props.Options.length; i < len; i++) {
            if (props.Options[i].Key === props.Value) {
                return [props.Options[i], null];
            } else if (props.Options[i].Options) {
                for (let j = 0, len = props.Options[i].Options.length; j < len; j++) {
                    if (props.Options[i].Options[j].Key === props.Value) {
                        return [props.Options[i].Options[j], props.Options[i]];
                    }
                }
            }
        }
        return [null, null];
    }

    const getSelectedOptions = () => {
        if (!props.MultiSelect || !props.Options || !props.Value) {
            return [];
        }
        const selectedOptions = [];
        for (let i = 0, len = props.Options.length; i < len; i++) {
            if (props.Value.includes(props.Options[i].Key)) {
                selectedOptions.push([props.Options[i], null]);
            } else if (props.Options[i].Options) {
                for (let j = 0, len = props.Options[i].Options.length; j < len; j++) {
                    if (props.Value.includes(props.Options[i].Options[j].Key)) {
                        selectedOptions.push([props.Options[i].Options[j], props.Options[i]]);
                    }
                }
            }
        }
        return selectedOptions;
    }

    const getOptionTitle = (option, parentOption) => {
        if (parentOption) {
            const parentTitle = parentOption.Title || parentOption.Value;
            if (parentTitle) {
                return parentTitle + SELECT_SUBITEM_SEPARATOR + (option.Title || option.Value);
            }
        }
        return option.Title || option.Value;
    }

    const getOptionLabel = (option, parentOption) => {
        if (parentOption && parentOption.Value) {
            if (parentOption.Value) {
                return parentOption.Value + SELECT_SUBITEM_SEPARATOR + option.Value;
            }
        }
        return option.Value;
    }

    const getClassName = () => {
        let className = "eone-ui-select-btn";
        if (props.Class) {
            className = `${className} ${props.Class}`;
        }
        return className;
    };

    const getContainerClass = () => {
        if (props.ContainerClass) return props.ContainerClass;
        let className = "eone-ui-select-container";
        if (hasFocus) {
            className = `${className} eone-ui-select-container-focused`;
        }
        return className;
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }

    if (props.Loading) {
        const loadingMessage = props.LoadingMessage || "Loading...";
        return (
            <div className={props.ContainerClass || "eone-ui-select-container"}>
                <div title={loadingMessage} className="eone-ui-select-loading" autoFocus={props.AutoFocus} data-testid={getDataTestId(props.DataTestId, "loading")}>
                    <span className="eone-ui-select-loading-icon">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </span>
                    <span className="eone-ui-select-loading-message">{loadingMessage}</span>
                </div>
            </div>
        );
    }

    let title = undefined;
    let label = undefined;
    let icon = undefined;
    let color = undefined
    let labelClass = undefined;

    if (props.MultiSelect) {
        const selectedOptions = getSelectedOptions();

        title = selectedOptions.length === 0
            ? props.Placeholder
            : selectedOptions.map(([option, parentOption]) => getOptionTitle(option, parentOption)).join(", ");
        label = selectedOptions.length === 0
            ? props.Placeholder
            : selectedOptions.map(([option, parentOption]) => getOptionLabel(option, parentOption)).join(", ");
        labelClass = (props.ReadOnly || selectedOptions.length > 0)
            ? "eone-ui-select-label"
            : "eone-ui-select-placeholder";

    } else { 
        const [selectedOption, selectedOptionParent] = getSelectedOption();

        title = selectedOption
            ? getOptionTitle(selectedOption, selectedOptionParent)
            : props.Placeholder;
        label = selectedOption
            ? getOptionLabel(selectedOption, selectedOptionParent)
            : props.Placeholder;
        icon = selectedOption
            ? selectedOption.Icon
            : undefined;
        color = selectedOption
            ? selectedOption.Color
            : undefined;
        labelClass = (props.ReadOnly || selectedOption)
            ? "eone-ui-select-label"
            : "eone-ui-select-placeholder";
        if (color || icon) {
            labelClass = `${labelClass} eone-ui-select-label-icon`;
        }
    }

    if (props.ReadOnly) {
        return (
            <div className={props.ContainerClass || "eone-ui-select-container"}>
                <div
                    title={title}
                    className="eone-ui-select-readonly"
                    autoFocus={props.AutoFocus}
                    data-testid={props.DataTestId}>
                    <span className={labelClass}>
                        <SelectOptionColor Color={color} />
                        <SelectOptionIcon Icon={icon} />
                        {label}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className={getContainerClass()} ref={triggerRef} >
            <button
                type="button"
                title={title}
                className={getClassName()}
                autoFocus={props.AutoFocus}
                onClick={toggleMenu}
                onFocus={setFocus}
                onKeyDown={handleKeyDown}
                onBlur={clearFocus}
                data-testid={props.DataTestId}>
                <span className={labelClass}>
                    <SelectOptionColor Color={color} />
                    <SelectOptionIcon Icon={icon} />
                    {label}
                </span>
                <span className="eone-ui-select-caret">
                    <FontAwesomeIcon icon={faAngleDown} />
                </span>
            </button>
            <DropdownPanel Style={style} Open={open} Close={close} DropdownPanelRef={dropdownPanelRef} >
                <SelectMenu
                    DropdownSubPanelRef={dropdownSubPanelRef}
                    Animating={animating}
                    Open={open}
                    MultiSelect={props.MultiSelect}
                    Value={props.Value}
                    DropdownPosition={position}
                    DropDirection={direction}
                    Options={props.Options}
                    Search={props.Search && props.Options && props.Options.length > SELECT_SEARCH_THRESHOLD}
                    SearchValue={searchValue}
                    ChangeSearch={search}
                    OnChange={changeHandler}
                    DataTestId={props.DataTestId} />
            </DropdownPanel>
        </div>
    );
}

export { Select };

