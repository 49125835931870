import { faCheck, faPlus, faSpinner, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FormSelect } from "eone-ui/forms/form-select";
import { FontAwesome } from "eone-ui/icons/icons";
import { Navigation } from "eone-ui/navigation/navigation";
import { FormPanel } from "eone-ui/panels/form-panel";
import { data_get, data_post } from "popdock-shared/Functions/data-access";
import { ConnectorAuthSetting } from "popdock-shared/connector-auth/connector-auth-setting";
import React, { useState, useEffect } from "react";
import { FormSpacer } from "eone-ui/forms/form-spacer";
import { AddConnectorMessage } from "./add-connector-message";
import { getJSON } from "./get-json";


function getUrlPathItem(index) {
    const path = window.location.pathname.split("/");
    if (path.length > index) {
        return path[index];
    }
    return null;
}

function AdditionalSettings(props) {
    const [connectorSetupId, setConnectorSetupId] = useState(null);
    const [connectorSetup, setConnectorSetup] = useState({
        Name: ""
    });
    const [connectorAuthId, setConnectorAuthId] = useState();
    const [settingsLoading, setSettingsLoading] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [error, setError] = useState(null);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        if (props.User) {
            loadAdditionalSettings();
        }
    }, [props.User]);

    const loadAdditionalSettings = async () => {
        const urlParameters = new URLSearchParams(window.location.search);
        const newConnectorAuthId = getUrlPathItem(3);
        const newConnectorSetupId = urlParameters.get("connectorSetupId");
        props.SetConnectorSetupId(newConnectorSetupId);
        const stage = urlParameters.get("stage");
        setConnectorSetupId(newConnectorSetupId);
        setConnectorAuthId(newConnectorAuthId);

        const newConnectorSetup = await data_get(`/AddConnector/ConnectorSetup/${newConnectorSetupId}`);
        const newAuthDocumentation = await getJSON(props.SettingsBaseUrl, "setup/connector-auths", `${newConnectorSetup.AuthsFile}.json`);
        props.SetHelpArticle(newAuthDocumentation);
        setConnectorSetup(newConnectorSetup);
        loadSettings(newConnectorAuthId, newConnectorSetupId, stage);

    };

    const loadSettings = async (authId, connectorSetupId, stage) => {
        setSettings([]);
        setSettingsLoading(true);
        const newSettings = await data_get(`/AddConnector/Settings/${authId}?stage=1`);
        const metadata = await data_get(`/AddConnector/SettingsMetadata/${authId}?connectorSetupId=${connectorSetupId}&stage=${stage}`);
        newSettings.sort((a, b) => a.SettingNumber - b.SettingNumber);
        for (let i = 0; i < newSettings.length; i++) {
            const options = metadata[newSettings[i].Name];
            if (options) {
                newSettings[i].Options = metadata[newSettings[i].Name];
                newSettings[i].FieldType = "Enum";
                newSettings[i].Value = newSettings[i].Options[0].Value;
            }
        }

        for (let i = 0; i < newSettings.length; i++) {
            if (!metadata[newSettings[i].Name]) {
                for (let j = 0; j < newSettings.length; j++) {
                    if (i !== j && newSettings[j].Options && newSettings[j].Options.length > 0 && newSettings[j].Options[0][newSettings[i].Name]) {
                        const baseOption = newSettings[j].Options.find(option => option.Key === newSettings[j].Value);
                        const options = baseOption[newSettings[i].Name];
                        newSettings[i].Options = options;
                        newSettings[i].FieldType = "Enum";
                        newSettings[i].Value = options[0].Key;
                    }
                }
            }
        }

        setSettings(newSettings);
        setSettingsLoading(false);
    };

    const changeSetting = (name, value) => {
        const newSettings = [...settings];
        const setting = settings.find(s => s.Name === name);

        for (let i = 0; i < newSettings.length; i++) {
            if (setting !== newSettings[i] && setting.Options && setting.Options[0][newSettings[i].Name]) {
                const baseOption = setting.Options.find(option => option.Key === value);
                if (baseOption) {
                    const options = baseOption[settings[i].Name];
                    newSettings[i].Options = options;
                    newSettings[i].FieldType = "Enum";
                    newSettings[i].Value = options[0].Key;
                }
            }
        }

        if (setting) {
            setting.Value = value;
            setSettings(newSettings);
        }
    };

    const connect = async () => {
        const userValues = settings.map(setting => {
            return {
                Name: setting.Name,
                Value: setting.Value
            };
        });
        setConnecting(true);
        setError("");
        const connectData = await data_post(`/AddConnector/ConnectWithAdditionalSettings/${connectorAuthId}?connectorSetupId=${connectorSetupId}`, userValues);
        if (connectData.error) {
            setError(connectData.error);
            setConnecting(false);
        }
        if (connectData.Status === "error") {
            setError(connectData.Error);
            setConnecting(false);
        }
        else if (connectData.Status === "redirect") {
            window.location = connectData.RedirectTo;
        }
    };

    const goBack = () => {
        window.location = "/AddConnector/" + connectorSetupId;
    }

    const getNavigationButtons = () => {
        return [
            {
                Label: connecting ? "Connecting..." : "Connect",
                Icon: connecting ? faSpinner : faPlus,
                IconSpin: connecting,
                OnClick: connect,
                Disabled: connecting
            },
            {
                Label: "Go back",
                Icon: faArrowLeft,
                OnClick: goBack

            }
        ];
    };


    const getSettings = () => {
        return settings.map((setting, index) => (
            <ConnectorAuthSetting
                key={setting.SettingId}
                Name={setting.Name}
                Label={setting.Label}
                Placeholder={setting.Placeholder}
                Tooltip={setting.Tooltip}
                Value={setting.Value}
                FieldType={setting.FieldType}
                Required={setting.Required}
                Options={setting.Options}
                AutoFocus={index === 0}
                Class={props.Class}
                OnChange={changeSetting} />
        ));
    };


    return (
        <Navigation ItemsLabel={"Steps"} Buttons={getNavigationButtons()} Items={props.NavigationItems} Selected={connectorSetupId}>
            <FormPanel Title={connectorSetup.Name} Icon={connectorSetup.Icon} IconType="af" Loading={settingsLoading}>
                {settingsLoading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 12 }}><FontAwesome Icon={faSpinner} Spin />Loading...</div> :
                    getSettings()}
                <AddConnectorMessage
                    ErrorMessage={error}
                    ConnectMessage={connecting ? "Connecting..." : null} />
            </FormPanel>
        </Navigation>
    );
}


export { AdditionalSettings };