import { FormCheckbox } from "eone-ui/forms/form-checkbox";
import { FormLabel } from "eone-ui/forms/form-label";
import { FormSelect } from "eone-ui/forms/form-select";
import { FormString } from "eone-ui/forms/form-string";
import {getDataTestId} from "eone-ui/functions/data-test-ids";

export function ConnectorAuthSetting(props) {
    const change = (value) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(props.Name, value.toString());
        }
    };
    
    const update = (value) => {
        if (typeof props.OnUpdate === "function") {
            props.OnUpdate(props.Name, value.toString());
        }
    };
    
    const changeAndUpdate = (value) => {
        change(value);
        update(value);
    };

    const getTooltip = () => {
        if (props.TooltipString) {
            return {
                Tooltip: props.TooltipString
            };
        }
        return null;
    };
    
    let component = null;
    switch (props.FieldType) {
        case "Password":
            component = (
                <FormString
                    Label={props.Label}
                    ShowRequiredIndicator={props.Required}
                    Password
                    Placeholder={props.Placeholder}
                    Tooltip={getTooltip()}
                    Value={props.Value}
                    Class={props.Class}
                    OnChange={change}
                    OnBlur={update}
                    AutoComplete={false}
                    ReadOnly={props.CanEdit === false} 
                    DataTestId={props.DataTestId}/>
            );
            break;
        case "Enum":
            component = (
                <FormSelect
                    Label={props.Label}
                    ShowRequiredIndicator={props.Required}
                    Placeholder={props.Placeholder}
                    Tooltip={getTooltip()}
                    Value={props.Value}
                    Class={props.Class}
                    Options={props.Options}
                    OnChange={changeAndUpdate}
                    ReadOnly={props.CanEdit === false} 
                    DataTestId={props.DataTestId}/>
            );
            break;
        case "Checkbox":
            component = (
                <FormCheckbox
                    Label={props.Label}
                    ShowRequiredIndicator={props.Required}
                    Tooltip={getTooltip()}
                    Value={props.Value}
                    Class={props.Class}
                    OnToggle={changeAndUpdate}
                    ReadOnly={props.CanEdit === false} 
                    DataTestId={props.DataTestId}/>
            );
            break;
        default:
            component = (
                <FormString
                    Label={props.Label}
                    ShowRequiredIndicator={props.Required}
                    Placeholder={props.Placeholder}
                    Tooltip={getTooltip()}
                    Value={props.Value}
                    Class={props.Class}
                    AutoFocus={props.AutoFocus}
                    OnChange={change}
                    OnBlur={update}
                    AutoComplete={false}
                    ReadOnly={props.CanEdit === false} 
                    DataTestId={props.DataTestId}/>
            );
            break;
    }

    if (props.Description) {
        return (
            <div>
                <FormLabel Label={props.Description} DataTestId={getDataTestId(props.DataTestId, "description")}/>
                {component}
            </div>
        );
    }

    else return component;
}
