import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getDataTestId} from "../functions/data-test-ids";

function InputIcon(props) {
    if (!props.Icon) {
        return null;
    }

    return (
        <div className="eone-ui-input-string-icon">
            <FontAwesomeIcon fixedWidth={true} icon={props.Icon} data-testid={getDataTestId(props.DataTestId, "icon")}/>
        </div>
    );
}

export { InputIcon };