import React from "react";
import { Icon } from "../icons/icons";
import { faChevronUp, faChevronDown, faSearch, faLineColumns } from "@fortawesome/pro-regular-svg-icons";
import { getDataTestId } from "../functions/data-test-ids";
import "./panels.css";
import { ButtonGroup } from "../buttons/button-group";
import { InputString } from "../inputs/input-string";
import { useHelpContext } from "../help-context";

const Markdown = React.lazy(() => import("../markdown/markdown"));

const ButtonMenuStyle = {
    List: 1,
    Tiles: 2
};

function PanelExplanationHelpLink(props) {
    const helpContext = useHelpContext();

    const navigateToHelp = () => {
        helpContext.push({ Id: props.Article, Type: "Article" });
    };

    if (!props.Article) {
        return null;
    }

    return (
        <div className="eone-ui-explanation-link-container">
            <a className="eone-ui-explanation-link" onClick={navigateToHelp}>Read more</a>
        </div>
    );
}

function PanelExplanation(props) {
    if (!props.Explanation || !props.Explanation.Message) {
        return null;
    }
    return (
        <div className="eone-ui-explanation-container">
            <Markdown children={props.Explanation.Message} />
            <PanelExplanationHelpLink Article={props.Explanation.RelatedArticle} />
        </div>
    );
}

function PanelHeader(props) {
    const selectTableColumns = () => {
        if (typeof props.SelectTableColumns === "function") {
            props.SelectTableColumns();
        }
    };

    const toggleCollapse = () => {
        if (typeof props.Collapse === "function") {
            props.Collapse();
        }
    };

    const search = (value) => {
        if (typeof props.OnSearch === "function") {
            props.OnSearch(value);
        }
    };

    const getButtons = () => {
        const buttons = !props.Buttons ? [] : props.Buttons.slice(0);
        if (props.ShowSelectTableColumns) {
            buttons.push(
                {
                    Label: props.Labels?.SelectColumns || "Select columns",
                    Icon: faLineColumns,
                    DataTestId: "select-table-columns",
                    OnClick: selectTableColumns
                }
            );
        }
        if (props.ShowCollapse) {
            buttons.push({
                Label: props.Collapsed ? props.Labels?.Show || "Show" : props.Labels?.Hide || "Hide",
                Icon: props.Collapsed ? faChevronDown : faChevronUp,
                DataTestId: props.Collapsed ? "panel-show" : "panel-collapse",
                OnClick: toggleCollapse
            });
        }
        return buttons;
    };

    const getIconType = () => {
        if (props.IconType) {
            return props.IconType;
        }
        return "fa";
    };

    if (!props.Title) {
        return null;
    }

    return (
        <div className="eone-ui-panel-heading-container">
            <h4 className="eone-ui-panel-heading">
                <Icon Icon={props.Icon} Type={getIconType()} IconStyle={{ fontSize: "large" }} FixedWidth />
                <span className="eone-ui-panel-heading-label" data-testid={getDataTestId(props.DataTestId, "title")}>{props.Title}</span>
            </h4>
            <div className="eone-ui-panel-header-controls-container">
                <ButtonGroup
                    Buttons={getButtons()}
                    DataTestId={props.DataTestId} />
                <InputString
                    Visible={props.Search === true}
                    Placeholder="Search"
                    Icon={faSearch}
                    Value={props.SearchValue}
                    OnChange={search}
                    DataTestId={getDataTestId(props.DataTestId, "search")} />
            </div>
        </div>
    );
}

function PanelBody(props) {
    if (props.Height !== undefined) {
        let divStyle = props.BodyStyle;
        if (divStyle) {
            divStyle.maxHeight = props.Height;
            divStyle.overflowY = "auto";
        } else {
            divStyle = { maxHeight: props.Height };
        }
        if (props.MinHeight) {
            divStyle.minHeight = props.MinHeight;
        }
        return (
            <div className={props.BodyClass} style={divStyle}>
                {props.children}
            </div>
        );
    }

    return (
        <div className={props.BodyClass} style={props.BodyStyle}>
            {props.children}
        </div>
    );
}

function Panel(props) {
    const selectTableColumns = () => {
        if (typeof props.SelectTableColumns === "function") {
            props.SelectTableColumns();
        }
    };

    const collapse = () => {
        if (typeof props.OnCollapse === "function") {
            props.OnCollapse();
        }
    };

    const clickHandler = (button) => {
        if (typeof props.OnClick === "function") {
            props.OnClick(button);
        }
    };

    const searchHandler = (value) => {
        if (typeof props.OnSearch === "function") {
            props.OnSearch(value);
        }
    };

    const filterHandler = (value) => {
        if (typeof props.OnFilter === "function") {
            props.OnFilter(value);
        }
    };

    const toggleCheckboxHandler = () => {
        if (typeof props.OnToggleCheckbox === "function") {
            props.OnToggleCheckbox();
        }
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }

    if (props.Collapsible && props.Collapsed) {
        return (
            <div className="eone-ui-panel-collapsed">
                <PanelHeader
                    Collapsed={true}
                    Icon={props.Icon}
                    IconType={props.IconType}
                    IconStyle={props.IconStyle}
                    Title={props.Title}
                    ShowCollapse
                    Collapse={collapse}
                    DataTestId={props.DataTestId} />
            </div>
        );
    }

    return (
        <div className="eone-ui-panel">
            <PanelHeader
                ShowCollapse={props.Collapsible}
                Collapsed={props.Collapsed}
                Collapse={collapse}
                Icon={props.Icon}
                IconType={props.IconType}
                IconStyle={props.IconStyle}
                Title={props.Title}
                Search={props.Search}
                Filter={props.Filter}
                FilterOptions={props.FilterOptions}
                FilterValue={props.FilterValue}
                Checkbox={props.Checkbox}
                CheckboxLabel={props.CheckboxLabel}
                CheckboxValue={props.CheckboxValue}
                Buttons={props.HeaderButtons}
                ShowSelectTableColumns={props.ShowSelectTableColumns}
                SelectTableColumns={selectTableColumns}
                OnClick={clickHandler}
                OnSearch={searchHandler}
                SearchValue={props.SearchValue}
                OnFilter={filterHandler}
                OnToggleCheckbox={toggleCheckboxHandler}
                TooltipInfo={props.TooltipInfo}
                DataTestId={props.DataTestId} />
            <PanelExplanation Explanation={props.Explanation} />
            <PanelBody BodyClass={props.BodyClass} BodyStyle={props.BodyStyle} Height={props.BodyHeight} MinHeight={props.BodyMinHeight}>
                {props.children}
            </PanelBody>
        </div>
    );
}

export { Panel, PanelHeader, PanelExplanation, ButtonMenuStyle };