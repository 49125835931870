import { Select } from "../select/select";
import { FormInput } from "./form-input";
import { FormSkeleton } from "./form-skeleton";

function FormSelect(props) {
    const changeHandler = (value, data) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(value, data);
        }
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }

    if (props.Skeleton) {
        return (
            <FormSkeleton
                Label={props.Label}
                DataTestId={props.DataTestId} />
        );
    }

    return (
        <FormInput
            Id={props.Id}
            Label={props.Label}
            ShowRequiredIndicator={props.ShowRequiredIndicator}
            RequiredStringIndicator={props.RequiredStringIndicator}
            Class={props.Class}
            Style={props.Style}
            Info={props.Info}
            Width={props.Width}
            Buttons={props.Buttons}
            DataTestId={props.DataTestId}
            HelpLabel={props.HelpLabel}
            HelpLink={props.HelpLink}
            Tooltip={props.Tooltip}
            TooltipTitle={props.TooltipTitle}
            TooltipWidth={props.TooltipWidth} >
            <Select
                Id={props.Id}
                ReadOnly={props.ReadOnly}
                Search={props.Search}
                Options={props.Options}
                MultiSelect={props.MultiSelect}
                Value={props.Value}
                Placeholder={props.Placeholder}
                AutoFocus={props.AutoFocus}
                Loading={props.Loading}
                OnChange={changeHandler}
                MaxHeight={props.MaxHeight}
                DataTestId={props.DataTestId} />

        </FormInput>
    );
}

export { FormSelect };
