import React, {useEffect, useRef, useState} from "react";
import {
    faClipboard,
    faClipboardCheck,
    faExternalLink,
    faInfoCircle
} from "@fortawesome/pro-regular-svg-icons";
import "./help-article-web.css";
import "./help-article-sidebar.css";
import "../help-web.css";
import "../help-sidebar.css";
import {
    HelpArticleItemCodeType, HelpArticleItemListType as HomeArticleItemListType,
    HelpArticleItemListType,
    HelpArticleItemType,
    HelpArticleRelatedArticleType
} from "./help-article-constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getTextContent} from "./help-text";

function HelpArticleRelatedItem(props) {
    const clickHandler = () => {
        props.OnClick("Article", props.Link);
    };
    
    const externalClickHandler = () => {
        window.open(props.Link, "_blank");
    };
    
    switch (props.Type) {
        case HelpArticleRelatedArticleType.Article:
            return (
                <div className={`eone-help-related-item-container-${props.Theme}`}>
                    <a className={`eone-help-related-item-${props.Theme}`} onClick={clickHandler}>{props.Name}</a>
                </div>
            );
        case HelpArticleRelatedArticleType.External:
            return (
                <div className={`eone-help-related-item-container-${props.Theme}`}>
                    <div className={`eone-help-related-item-external-icon-${props.Theme}`}><FontAwesomeIcon icon={faExternalLink}/></div>
                    <a className={`eone-help-related-item-${props.Theme}`} onClick={externalClickHandler}>{props.Name}</a>
                </div>
            );
    }
}

function HelpArticleRelated(props) {
    const getRelatedArticles = () => {
        return props.RelatedArticles.map((item, index) => {
            return (
                <HelpArticleRelatedItem
                    key={index}
                    Link={item.link}
                    Type={item.type || HelpArticleRelatedArticleType.Article}
                    Name={item.name}
                    Theme={props.Theme}
                    OnClick={props.OnClick}/>
            );
        });
    };

    if (!props.RelatedArticles || props.RelatedArticles.length === 0) {
        return null;
    }

    return (
        <div className={`eone-help-related-items-container-${props.Theme}`}>
            <div className={`eone-help-related-title-${props.Theme}`}>See also</div>
            {getRelatedArticles()}
        </div>
    );
}

function HelpArticleVideo(props) {
    const videoRef = useRef();
    const [height, setHeight] = useState("1px");

    useEffect(() => {
        if (videoRef && videoRef.current) {
            const width = videoRef.current.clientWidth;
            setHeight((width * 3 / 4).toString() + "px");
        }
    }, []);

    return (
        <div className="eone-ui-help-video-container" style={{ height: height }} >
            <iframe ref={videoRef} src={props.Value} allowFullScreen className="eone-help-video" />
        </div>
    );
}

function HelpArticleTip(props) {
    return (
        <div className={`eone-help-tip-container-${props.Theme}`}>
            <div className={`eone-help-tip-icon-${props.Theme}`}>
                <FontAwesomeIcon icon={faInfoCircle} size="2x"/>
            </div>
            <div className={`eone-help-tip-text-${props.Theme}`}>
                {getTextContent(props.Value)}
            </div>
        </div>
    );
}

function HelpArticleCodeCopyButton(props) {
    if (props.Copied) {
        return (
            <div className={`eone-help-code-header-copied-${props.Theme}`}>
                <FontAwesomeIcon icon={faClipboardCheck} fixedWidth={true}/>
                <label className={`eone-help-code-header-copy-label-${props.Theme}`}>Copied</label>
            </div>
        );
    }

    return (
        <div className={`eone-help-code-header-copy-${props.Theme}`} onClick={props.Copy}>
            <FontAwesomeIcon icon={faClipboard} fixedWidth={true}/>
            <label className={`eone-help-code-header-copy-label-${props.Theme}`}>Copy</label>
        </div>
    );
}

function HelpArticleCode(props) {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(props.Value)
            .then(() => {
                setCopied(true);
            })
            .then(() => {
                setTimeout(clearCopiedState, 5000);
            });
    };

    const clearCopiedState = () => {
        setCopied(false);
    };

    const getCodeTypeName = () => {
        switch (props.CodeType) {
            case HelpArticleItemCodeType.PowerShell:
                return "PowerShell"
            case HelpArticleItemCodeType.PopdockScript:
                return "Popdock script"
            case HelpArticleItemCodeType.Sql:
                return "SQL"
            case HelpArticleItemCodeType.Json:
                return "JSON"
            case HelpArticleItemCodeType.Xml:
                return "XML"
            default:
                return "";
        }
    };

    const formatJson = (jsonString) => {
        try {
            const parsedJSON = JSON.parse(jsonString);
            return JSON.stringify(parsedJSON, null, 4);
        } catch (error) {
            return jsonString;
        }
    }
    
    const getCodeText = () => {
        if (props.CodeType === HelpArticleItemCodeType.Json) {
            return formatJson(props.Value);
        }
        return props.Value;
    };
    
    return (
        <div className={`eone-help-code-container-${props.Theme}`}>
            <div className={`eone-help-code-header-${props.Theme}`}>
                <label className={`eone-help-code-header-label-${props.Theme}`}>{getCodeTypeName()}</label>
                <HelpArticleCodeCopyButton
                    Copied={copied}
                    Copy={copyToClipboard}
                    Theme={props.Theme}/>
            </div>
            <pre className={`eone-help-code-text-${props.Theme}`}>{getCodeText()}</pre>
        </div>
    );
}

function HelpArticleItem(props) {
    const getChildItems = () => {
        if (!props.Items) {
            return null;
        }

        return props.Items.map((item, index) => {
            return (
                <HelpArticleItem
                    key={index}
                    Type={item.type}
                    Value={item.value}
                    AltText={item.altText}
                    CodeType={item.codeType}
                    Items={item.Items}
                    Theme={props.Theme}
                    BaseUrl={props.BaseUrl}/>
            );
        });
    };

    switch (props.Type) {
        case HelpArticleItemType.Text:
            return (
                <div className={`eone-help-item-text-${props.Theme}`}>
                    {getTextContent(props.Value)}
                </div>
            );
        case HelpArticleItemType.Image:
            if (props.Theme === "sidebar" && props.HideInSidebar === true) {
                return null;
            }
            return (
                <div className={`eone-help-item-image-${props.Theme}`}>
                    <img style={{maxWidth: "100%", border: "thin solid var(--border-color)"}} src={`${props.BaseUrl}/images/${props.Value}`} alt={props.AltText}/>
                </div>
            );
        case HelpArticleItemType.Title:
            return (
                <div className={`eone-help-item-title-${props.Theme}`}>{props.Value}</div>
            );
        case HelpArticleItemType.Subtitle:
            return (
                <div className={`eone-help-item-subtitle-${props.Theme}`}>{props.Value}</div>
            );
        case HelpArticleItemType.Iframe:
            return (
                <div className={`eone-help-item-iframe-${props.Theme}`}>
                    <iframe src={props.Value}></iframe>
                </div>
            );
        case HelpArticleItemType.Tip:
            return (
                <HelpArticleTip
                    Value={props.Value}
                    Theme={props.Theme}/>
            );
        case HelpArticleItemType.Code:
            return (
                <HelpArticleCode
                    Value={props.Value}
                    CodeType={props.CodeType}
                    Theme={props.Theme}/>
            );
        case HelpArticleItemType.Link:
            return (
                <div className={`eone-help-item-link-${props.Theme}`}>
                    <a>{props.Value}</a>
                </div>
            );
        case HelpArticleItemType.ListItem:
            return (
                <li className={`eone-help-item-bulleted-list-${props.Theme}`}>{props.Value}</li>
            );
        case HelpArticleItemType.Video:
            return (
                <HelpArticleVideo Value={props.Value}/>
            );
        case HelpArticleItemType.ListItemGroup:
            if (props.ListType === HelpArticleItemListType.Number) {
                return (
                    <ol type="1">{getChildItems()}</ol>
                );
            } else {
                return (
                    <ul className={`eone-help-item-bulleted-list-${props.Theme}`}>{getChildItems()}</ul>
                );
            }
        default:
            return null;
    }
}

function HelpArticleItems(props) {
    const getFirstAndLastListItems = (items) => {
        let listItems = [];
        let lastListItemIndex = null;
        for (let i = 0, len = items.length; i < len; i++) {
            let item = Object.assign({}, items[i]);
            if (item.type === HelpArticleItemType.ListItem && item.listType === HomeArticleItemListType.Number) {
                if (lastListItemIndex == null || item.startOfList) {
                    item.startOfList = true;
                    if (lastListItemIndex != null) {
                        listItems[lastListItemIndex].endOfList = true;
                    }
                }
                lastListItemIndex = i;
            }
            listItems.push(item);
        }
        if (lastListItemIndex != null) {
            listItems[lastListItemIndex].endOfList = true;
        }
        return listItems;
    };

    const replaceBulletedListItems = () => {
        let listItems = [];
        let bulletedListItems = [];
        for (let i = 0, len = props.Items.length; i < len; i++) {
            if (props.Items[i].type === HelpArticleItemType.ListItem && props.Items[i].listType === HomeArticleItemListType.Bullet) {
                bulletedListItems.push(props.Items[i]);
            } else {
                if (bulletedListItems.length > 0) {
                    listItems.push({
                        type: HelpArticleItemType.ListItemGroup,
                        listType: HelpArticleItemListType.Bullet,
                        Items: bulletedListItems
                    });
                    bulletedListItems = [];
                }
                listItems.push(props.Items[i]);
            }
        }
        if (bulletedListItems.length > 0) {
            listItems.push({
                type: HelpArticleItemType.ListItemGroup,
                listType: HelpArticleItemListType.Bullet,
                Items: bulletedListItems
            });
        }
        return listItems;
    };
    
    const addListItemGroups = () => {
        let listItems = replaceBulletedListItems();
        listItems = getFirstAndLastListItems(listItems);
        let itemsWithGroups = [];
        let listGroupIndex = null;
        let itemIndex = 0;
        for (let i = 0, len = listItems.length; i < len; i++) {
            if (listGroupIndex !== null) {
                itemsWithGroups[listGroupIndex].Items.push(listItems[i]);
                if (listItems[i].endOfList) {
                    listGroupIndex = null;
                }
            } else {
                if (listItems[i].startOfList) {
                    if (!listItems[i].endOfList) {
                        listGroupIndex = itemIndex;
                    }
                    itemsWithGroups.push({
                        type: HelpArticleItemType.ListItemGroup,
                        listType: listItems[i].listType,
                        Items: [listItems[i]]
                    });
                } else {
                    itemsWithGroups.push(listItems[i]);
                }
                itemIndex++;
            }
        }
        return itemsWithGroups;
    };

    const getItems = () => {
        const numericItems = addListItemGroups();
        return numericItems.map((item, index) => {
            return (
                <HelpArticleItem
                    key={index}
                    Type={item.type}
                    ListType={item.listType}
                    Value={item.value}
                    AltText={item.altText}
                    CodeType={item.codeType}
                    StartOfList={item.startOfList}
                    HideInSidebar={item.hideInSidebar}
                    Items={item.Items}
                    Theme={props.Theme}
                    BaseUrl={props.BaseUrl}/>
            );
        });
    };

    if (!props.Items || props.Items.length === 0) {
        return null;
    }

    return (
        <div className={`eone-help-item-container-${props.Theme}`}>
            {getItems()}
        </div>
    );
}

function HelpArticleSummary(props) {
    const clickHandler = () => {
        props.OnClick("Article", props.Link);
    };

    if (props.Type === "Subtitle") {
        return (
            <div className={`eone-help-summary-subtitle-${props.Theme}`}>{props.Name}</div>
        );
    }

    return (
        <div className={`eone-help-summary-container-${props.Theme}`}>
            <a className={`eone-help-summary-title-${props.Theme}`} onClick={clickHandler}>{props.Name}</a>
            <div className={`eone-help-summary-text-${props.Theme}`}>{props.Summary}</div>
        </div>
    );
}

function HelpArticleSummaries(props) {
    const getArticleSummaries = (articles) => {
        return articles.map((item, index) => {
            return (
                <HelpArticleSummary
                    key={index}
                    Type={item.type}
                    Link={item.link}
                    Name={item.name}
                    Summary={item.summary}
                    Theme={props.Theme}
                    OnClick={props.OnClick}/>
            );
        });
    };

    if (!props.Articles || props.Articles.length === 0) {
        return null;
    }

    if (props.Articles.filter((item) => item.pinToTop).length === 0) {
        return (
            <div className={`eone-help-summary-list-container-${props.Theme}`}>
                {getArticleSummaries(props.Articles)}
            </div>
        );
    }
    
    return (
        <div className={`eone-help-summary-list-container-${props.Theme}`}>
            <div className={`eone-help-summary-list-spacer-${props.Theme}`}></div>
            {getArticleSummaries(props.Articles.filter((item) => item.pinToTop))}
            <div className={`eone-help-summary-list-spacer-${props.Theme}`}></div>
            {getArticleSummaries(props.Articles.filter((item) => !item.pinToTop))}
        </div>
    );
}

function HelpArticleDescription(props) {
    if (!props.Description) {
        return null;
    }

    return (
        <div className={`eone-help-description-${props.Theme}`}>{props.Description}</div>
    );
}

function HelpArticleRenderer(props) {
    if (props.Visible !== undefined && props.Visible !== true) {
        return null;
    }

    return (
        <div className={`eone-help-panel-${props.Theme}`}>
            <div className={`eone-help-container-${props.Theme}`}>
                <div className={`eone-help-title-${props.Theme}`}>{props.Name}</div>
                <HelpArticleDescription Description={props.Description} Theme={props.Theme}/>
                <HelpArticleSummaries
                    Articles={props.Articles}
                    Theme={props.Theme}
                    OnClick={props.OnClick}/>
                <HelpArticleItems
                    Items={props.Items}
                    Theme={props.Theme}
                    BaseUrl={props.BaseUrl}/>
                <HelpArticleRelated
                    RelatedArticles={props.RelatedArticles}
                    Theme={props.Theme}
                    OnClick={props.OnClick}/>
            </div>
        </div>
    )
}

export { HelpArticleRenderer };