import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/pro-regular-svg-icons";
import {getDataTestId} from "../functions/data-test-ids";

function InputErrorIndicator(props) {
    if (!props.Error) {
        return null;
    }

    return (
        <div className="eone-ui-input-string-error" title={props.Error} data-testid={getDataTestId(props.DataTestId, "error-indicator")}>
            <FontAwesomeIcon icon={faExclamationCircle}/>
        </div>
    )
}

export { InputErrorIndicator }