import React from "react";
import { FormInput } from "./form-input";
import { InputString } from "../inputs/input-string";
import { FormSkeleton } from "./form-skeleton";

function FormString(props) {
    const changeHandler = (value) => {
        if (typeof props.OnChange === "function") {
            props.OnChange(value, props.Data);
        }
    };

    const copyHandler = (id) => {
        if (typeof props.OnCopy === "function") {
            props.OnCopy(id);
        }
    };

    const blurHandler = (value) => {
        if (typeof props.OnBlur === "function") {
            props.OnBlur(value, props.Data);
        }
    };

    const focusHandler = () => {
        if (typeof props.OnFocus === "function") {
            props.OnFocus();
        }
    };

    const errorHandler = (value) => {
        if (typeof props.OnError === "function") {
            props.OnError(value);
        }
    };

    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }

    if (props.Skeleton) {
        return (
            <FormSkeleton
                Label={props.Label}
                DataTestId={props.DataTestId} />
        )
    }

    return (
        <FormInput
            Id={props.Id}
            Label={props.Label}
            ShowRequiredIndicator={props.ShowRequiredIndicator}
            RequiredStringIndicator={props.RequiredStringIndicator ?? "*"}
            Class={props.Class}
            Style={props.Style}
            Info={props.Info}
            Width={props.Width}
            Buttons={props.Buttons}
            DataTestId={props.DataTestId}
            Tooltip={props.Tooltip}
            TooltipTitle={props.TooltipTitle}
            TooltipWidth={props.TooltipWidth} >

            <InputString
                Id={props.Id}
                Label={props.Label}
                Icon={props.Icon}
                AutoFocus={props.AutoFocus}
                AutoComplete={props.AutoComplete}
                Placeholder={props.Placeholder}
                ReadOnly={props.ReadOnly}
                Password={props.Password}
                MaxLength={props.MaxLength}
                Style={props.InputStyle}
                Value={props.Value}
                Required={props.Required}
                RequiredError={props.RequiredError}
                InvalidValues={props.InvalidValues}
                InvalidError={props.InvalidError}
                InvalidChars={props.InvalidChars}
                ValidChars={props.ValidChars}
                ShowCopyButton={props.ShowCopyButton}
                ErrorMessage={props.ErrorMessage}
                OnEnter={props.OnEnter}
                OnChange={changeHandler}
                OnBlur={blurHandler}
                OnFocus={focusHandler}
                OnError={errorHandler}
                OnCopy={copyHandler}
                DataTestId={props.DataTestId} />

        </FormInput>
    );
}

export { FormString };