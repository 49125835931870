import { faArrowLeft, faQuestionCircle, faUser } from "@fortawesome/pro-regular-svg-icons";
import { HelpArticleRenderer } from "eone-help-renderer/help/help-article";
import { data_get } from "popdock-shared/Functions/data-access";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Button } from "eone-ui/buttons/button";
import { AddConnector } from "./add-connector";
import { AdditionalSettings } from "./additional-settings";
import './css/appfont.css';
import './css/site.css';
import { getJSON } from "./get-json";
import { NotFoundErrorPanel } from "./not-found";
import { ShowWidgetIds } from "./show-widget-ids";
import { NotAuthorizedErrorPanel } from "./unauthorized";
import { UserModal } from "./user-modal";

const getRoutes = (props) => {
	return [

		{
			path: "AddConnector/:id",
			element: <AddConnector {...props} />,
		},
		{
			path: "AddConnector/AdditionalSettings/:id",
			element: <AdditionalSettings {...props} />,
		},
		{
			path: "ShowWidgetIds",
			element: <ShowWidgetIds {...props} />,
		},
		{
			path: "Unauthorized",
			element: <NotAuthorizedErrorPanel />,
		},
		{
			path: "*",
			element: <NotFoundErrorPanel />,
		},
	];
}

function PageHeader(props) { 
	const getImageStyle = () => {
		return {
			paddingLeft: 12,
			width: "40px",
			height: "40px",
		};
	}

	return (
		<div style={{
			height: "52px",
			background: "white",
			borderBottom: "1px solid var(--border-color)",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center", 
			paddingLeft: 12,
			paddingRight: 12
		}}>
			<img src="/Images/popdock-cloud-logo-blue.svg" style={getImageStyle()} />
			<Button
				Visible={props.User}
				Icon={faUser}
				Label={props.User?.Name || props.User?.Email}
				Inline={false}
				OnClick={props.User ? props.ShowUserModal : () => { }} />
			<Button Label={"Help"} Icon={faQuestionCircle} OnClick={props.ToggleHelp} />
		</div>
	);
}

function HelpSidebar(props) {
	if (!props.Visible) return null;
	return (
		<div style={{ width: "20%", height: "calc(100vh - 53px)", overflow: "scroll" }}>
			<div style={{paddingLeft: 16, paddingTop: 16} }>
				<Button Visible={props.ShowBack} Label={"Back"} Icon={faArrowLeft} OnClick={props.Back} />
			</div>
			<HelpArticleRenderer
				Visible={!!props.Article}
				Name={props.Article?.Title}
				Description={props.Article?.Description}
				Items={props.Article?.Items}
				Articles={props.Article?.Articles}
				Related={props.Article?.Related}
				Theme="sidebar"
				OnClick={props.OnClick}
				BaseUrl={props.SettingsBaseUrl} />
		</div>
	);
}

function App(props) {
	const [userModal, setUserModal] = useState({ Show: false });
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [article, setArticle] = useState(null);
	const [articles, setArticles] = useState([]);
	const [showHelp, setShowHelp] = useState(false);
	const [connectorSetupId, setConnectorSetupId] = useState();

	useEffect(() => {
		const loadUser = async () => {
			const user = await data_get("/User");
			if (!user.error) {
				setUser(user);
			}
			setLoading(false);
		}
		loadUser();
	}, []);

	const showUserModal = () => {
		setUserModal({ Show: true });
	}

	const closeUserModal = () => {
		setUserModal({ Show: false });
	}

	const logout = () => {
		window.location = '/Logout';
	}

	const openArticle = (newArticle) => {
		setShowHelp(true);
		setArticle(newArticle);
		setArticles([newArticle]);
	}

	const hideHelp = () => { 
		setShowHelp(false);
	}

	const toggleHelp = () => {
		setShowHelp(x => !x);
	}

	const getNavigationItems = () => {
		if (!user) return null;
		return [
			{
				Item: user.Connectors[0].ConnectorId.toLowerCase(),
				Label: `Setup ${user.Connectors[0].Name}`,
				Icon: user.Connectors[0].Icon,
				IconType: "af",
				Visible: true,
				Disabled: connectorSetupId?.toLowerCase() != user.Connectors[0].ConnectorId.toLowerCase(),
			},
			{
				Item: user.Connectors[1].ConnectorId.toLowerCase(),
				Label: `Setup ${user.Connectors[1].Name}`,
				Icon: user.Connectors[1].Icon,
				IconType: "af",
				Visible: true,
				Disabled: connectorSetupId?.toLowerCase() != user.Connectors[1].ConnectorId.toLowerCase(),
			},
			{
				Item: "complete-install",
				Label: "Complete install",
				Icon: "popdock",
				IconType: "af",
				Visible: true,
				Disabled: connectorSetupId,
			},
		];
	};

	const changeHelpItem = async (type, link) => {
		const newArticle = await getJSON(user.SettingsBaseUrl, "documentation", `help-articles/${link}.json`);
		setArticles([...articles, newArticle]);
	}

	const helpBack = () => {
		let newArticles = [...articles];
		newArticles.pop();
		setArticles(newArticles);
	}

	return (
		<div>
			<UserModal
				Show={userModal.Show}
				Close={closeUserModal}
				User={user}
				Logout={logout} />
			<PageHeader User={user} ShowUserModal={showUserModal} ToggleHelp={toggleHelp} />
			<div style={{ display: "flex" }}>
				<div style={{ height: "calc(100vh - 53px)", width: showHelp ? "80%" : "100%", overflow: "scroll" }}>
					<Routes>
						{getRoutes({
							SettingsBaseUrl: user?.SettingsBaseUrl,
							SetConnectorSetupId: setConnectorSetupId,
							SetHelpArticle: openArticle,
							HideHelp: hideHelp,
							User: user,
							NavigationItems: getNavigationItems()
						}).map((route, index) => <Route element={route.element} path={route.path} key={index} />)}
					</Routes>
				</div>
				<HelpSidebar
					Visible={showHelp}
					Article={articles[articles.length - 1]}
					SettingsBaseUrl={user?.SettingsBaseUrl}
					Back={helpBack}
					ShowBack={articles.length> 1}
					OnClick={changeHelpItem} />
			</div>
		</div>
	);
}

const root = createRoot(document.getElementById("content"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);