import "./panels.css";

function ErrorPanel(props) {

    const getImage = () => {
        if (props.Image) {
            return `https://popdock-images.azureedge.net/images/${props.Image}.png`;
        }
        return "https://popdock-images.azureedge.net/images/cloudy_notfound.png";
    };

    const getPromptButtons = () => { 
        return props.Prompts?.filter(prompt => (prompt.Visible === undefined) || prompt.Visible).map((prompt, index) => {
            return (
                <a key={index} className="eone-ui-error-panel-link" onClick={prompt.OnClick}>
                    {prompt.Label}
                </a>
            );
        });
    }

    return (
        <div className="eone-ui-error-panel">
            <img src={getImage()} className="eone-ui-error-panel-image" alt={props.Title} />
            <div className="eone-ui-error-panel-title">{props.Title}</div>
            <div className="eone-ui-error-panel-message">{props.Message}</div>
            <div className="eone-ui-error-panel-prompts">
                {getPromptButtons()}
            </div>
        </div>
    );
}

export { ErrorPanel };
