import { faInfoCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "../buttons/button";
import { getDataTestId } from "../functions/data-test-ids";
import { useHelpContext } from "../help-context";
import { FontAwesome } from "../icons/icons";
import "./tooltip.css";

const Markdown = React.lazy(() => import("../markdown/markdown"));

export const TooltipDropDirection = {
    UpLeft: 1,
    UpRight: 2,
    DownLeft: 3,
    DownRight: 4
}

export const TooltipWidth = {
    Default: 300,
    Large: 500
}

const DefaultTooltipWidth = 300;

function TooltipFooter(props) {
    const navigateToHelp = () => {
        if (typeof props.ShowHelp === "function") {
            props.ShowHelp({ Type: "Article", Id: props.Article });
        }
    };
    
    return (
        <div className="eone-ui-tooltip-footer">
            <Button
                Visible={Boolean(props.Article)}
                Label={"More information"}
                OnClick={navigateToHelp}
                DataTestId={getDataTestId(props.DataTestId, "tooltip", "read-more")} />
            <Button
                Label={"Got it"}
                OnClick={props.Close}
                DataTestId={getDataTestId(props.DataTestId, "tooltip", "got-it")} />
        </div>
    );
}

function TooltipWindow(props) {
    const helpContext = useHelpContext();

    const showHelp = (article) => {
        helpContext?.push(article);
        props.Close();
    };

    if (!props.Show || !props.Style) {
        return null;
    }
    return ReactDOM.createPortal(
        <div ref={props.TooltipRef} data-testid={getDataTestId(props.DataTestId, "tooltip")} className={"eone-ui-tooltip"} style={props.Style}>
            <div className="eone-ui-tooltip-header">
                <div className="eone-ui-tooltip-title" data-testid={getDataTestId(props.DataTestId, "tooltip", "title")}>{props.Title}</div>
                <Button
                    Icon={faTimes}
                    OnClick={props.Close}
                    Danger
                    DataTestId={getDataTestId(props.DataTestId, "tooltip", "header-close-button")} />
            </div>
            <div className="eone-ui-tooltip-content" data-testid={getDataTestId(props.DataTestId, "tooltip", "content")}>
                <Markdown>
                    {props.Tooltip.Message}
                </Markdown>
            </div>
            <TooltipFooter
                Article={props.Tooltip.RelatedArticle}
                ShowHelp={showHelp}
                Close={props.Close}
                DataTestId={props.DataTestId} />
        </div>,
        document.body
    );
}

function Tooltip(props) {
    const [show, setShow] = useState(false);
    const buttonRef = useRef(null);
    const tooltipRef = useRef(null);
    const [style, setStyle] = useState(null);

    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (!buttonRef.current) return;
            if (!tooltipRef.current) return;
            if (buttonRef.current.contains(e.target)) return;
            if (tooltipRef.current.contains(e.target)) return;
            close();
        };
        document.addEventListener("mousedown", handleDocumentClick);
        return () => {
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, []);
    
    const getStyle = () => {
        if (!buttonRef.current) {
            return;
        }
        const button = buttonRef.current.getBoundingClientRect();
        const upHeightAvailable = button.top;
        const downHeightAvailable = window.innerHeight - button.bottom;
        const leftWidthAvailable = button.right;
        const rightWidthAvailable = window.innerWidth - button.left;

        const isLeft = rightWidthAvailable < 315;
        const isUp = downHeightAvailable < 315;

        if (isLeft) {
            if (isUp) {
                return {
                    bottom: window.innerHeight - button.top + 10,
                    right: window.innerWidth - button.right,
                    maxWidth: props.TooltipWidth || DefaultTooltipWidth,
                    maxHeight: 300,
                    transformOrigin: "bottom right",
                    width: props.TooltipWidth || DefaultTooltipWidth,
                };
            } else {
                return {
                    top: button.bottom + 10,
                    right: window.innerWidth - button.right,
                    maxWidth: props.TooltipWidth || DefaultTooltipWidth,
                    maxHeight: 300,
                    transformOrigin: "top right",
                    width: props.TooltipWidth || DefaultTooltipWidth,
                };
            }
        } else {
            if (isUp) {
                return {
                    bottom: window.innerHeight - button.top + 10,
                    left: button.left,
                    maxWidth: rightWidthAvailable,
                    maxHeight: 300,
                    transformOrigin: "bottom left",
                    width: props.TooltipWidth || DefaultTooltipWidth,
                };
            } else {
                return {
                    top: button.bottom + 10,
                    left: button.left,
                    maxWidth: rightWidthAvailable,
                    maxHeight: downHeightAvailable,
                    transformOrigin: "top left",
                    width: props.TooltipWidth || DefaultTooltipWidth,
                };
            }
        }
    };

    const toggleOpen = () => {
        if (!show) { 
            setStyle(getStyle());
        }
        setShow(!show);
    };

    const close = (e) => {
        setShow(false);
    };

    if (!props.Tooltip || !props.Tooltip.Message) {
        return null;
    }

    return (
        <div>
            <a ref={buttonRef} className="eone-ui-tooltip-icon" onClick={toggleOpen} data-testid={getDataTestId(props.DataTestId, "tooltip", "button")}>
                <FontAwesome Icon={props.Icon ? props.Icon : faInfoCircle} IconStyle={props.IconStyle} FixedWidth />
            </a>
            <TooltipWindow
                Show={show}
                TooltipRef={tooltipRef}
                Title={props.Title}
                Tooltip={props.Tooltip}
                Style={style}
                TooltipWidth={props.TooltipWidth}
                Close={close}
                DataTestId={props.DataTestId} />
        </div>
    );
}

export { Tooltip };
