import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faClipboard, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "../tooltip/tooltip";
import {useHelpContext} from "../help-context";
import {getDataTestId} from "../functions/data-test-ids";
import { Button } from "../buttons/button";

const DefaultRequiredStringIndicator = " *";

function FormInputCopyToClipboardButton(props) {
    const [copied, setCopied] = useState(false);

    const updateCopied = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
    };

    const copyToClipboard = async () => {
        updateCopied();
        props.Copy();
    };

    if (!props.Visible) {
        return null;
    }
    
    const clipboardIcon = copied ? faClipboardCheck : faClipboard;
    return (
        <a className="eone-ui-form-label-button" onClick={copyToClipboard} data-testid={getDataTestId(props.DataTestId, "copy-to-clipboard")}>
            <FontAwesomeIcon icon={clipboardIcon}/>
            <label className="eone-ui-form-label-button-label">Copy to clipboard</label>
        </a>
    );
}

function HelpLinkButton(props) {
    const helpContext = useHelpContext();

    const navigateToHelp = () => {
        helpContext.push(props.HelpLink);
    };

    if (!props.HelpLink || !props.HelpLink.Type || !props.HelpLink.Id) return null;
    return (
        <a className="eone-ui-form-input-button" onClick={navigateToHelp} data-testid={getDataTestId(props.DataTestId, "help")}>
            {props.HelpLabel}
        </a>
    );
}

function FormInputError(props) {
    if (!props.Error) {
        return null;
    }
    
    return (
        <label className="eone-ui-form-error" data-testid={getDataTestId(props.DataTestId, "error")}>
            {props.Error}
        </label>
    );
}

function FormInput(props) {
    const getButtons = () => {
        if (props.Buttons === undefined || props.Buttons === null || props.Buttons.length === 0) {
            return null;
        }
        return props.Buttons.map((button, index) => {
            return (
                <Button
                    key={index}
                    Id={button.Id}
                    Label={button.Label}
                    Title={button.Title}
                    Icon={button.Icon}
                    IconStyle={button.IconStyle}
                    MenuItems={button.MenuItems}
                    Style={button.Style}
                    Data={button.Data}
                    OnClick={button.OnClick}
                    DataTestId={getDataTestId(props.DataTestId, button.Label)}/>
            );
        });
    };

    const getLabel = () => {
        if (props.ShowRequiredIndicator) {
            return props.Label + (props.RequiredStringIndicator || DefaultRequiredStringIndicator);
        }
        return props.Label;
    }
    
    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    
    return (
        <div className="eone-ui-form-group" style={props.Style}>
            <div className="eone-ui-form-label-container">
                <label className="eone-ui-form-label" htmlFor={props.Id} data-testid={getDataTestId(props.DataTestId, "label")}>
                    <span className={props.HeaderClass}>{getLabel()}</span>
                    <Tooltip
                        Title={props.TooltipTitle || props.Label}
                        Tooltip={props.Tooltip}
                        TooltipWidth={props.TooltipWidth}
                        DataTestId={props.DataTestId}/>
                </label>
                <div className="eone-ui-form-label-button-container">
                    {getButtons()}
                    <FormInputCopyToClipboardButton
                        Visible={props.ShowCopyButton}
                        Copy={props.Copy}
                        DataTestId={props.DataTestId}/>
                    <HelpLinkButton
                        HelpLabel={props.HelpLabel}
                        HelpLink={props.HelpLink}
                        DataTestId={props.DataTestId}/>
                </div>
            </div>
            {props.children}
            <FormInputError 
                Error={props.Error}
                DataTestId={props.DataTestId}/>
        </div>
    );
}

export {FormInput};