import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesome } from "eone-ui/icons/icons";
import React from "react";

export function AddConnectorMessage(props) {
    if (props.ConnectMessage) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 12 }}><FontAwesome Icon={faSpinner} Spin />{props.ConnectMessage}</div>
        );
    }
    else if (props.ErrorMessage) {
        return (
            <div>
                <label className="pd-input-error-addon">
                    {props.ErrorMessage}
                </label>
            </div>
        );
    }
    else if (props.SuccessMessage) {
        return (
            <div>
                {props.SuccessMessage}
            </div>
        );
    }

    else {
        return null;
    }
}
