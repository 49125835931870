import {useState, useEffect, useRef} from "react";

export async function data_get(endpoint, signal) {
    try {
        const fetchRequestBody = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            },
            signal: signal
        };
        if (window.popdockBearerToken) {
            fetchRequestBody.headers = {
                "Authorization": `Bearer ${window.popdockBearerToken}`
            };
        }
        const results = await fetch(endpoint, fetchRequestBody);
        if (results.ok) {
            const resultsText = await results.text();
            if (resultsText) {
                const resultsJson = JSON.parse(resultsText);
                if (resultsJson && resultsJson.Redirect && window.location.href.replace(window.location.origin, "") !== resultsJson.Redirect) {
                    window.location = resultsJson.Redirect;
                }
                return resultsJson;
            } else {
                return resultsText;
            }
        } else {
            return {
                status: results.status,
                error: (await results.text()) || "Something went wrong"
            };
        }
    } catch (error) {
        console.log("get " + endpoint);
        console.log(error);
        return { error: error };
    }
}

export async function data_post(endpoint, body, contentType) {
    try {
        const fetchRequestBody = {
            method: "POST",
            headers: {
                "Content-Type": contentType || "application/json", 
                "Accept": "application/json"
            },
            mode: "cors",
            body: JSON.stringify(body)
        };
        if (window.popdockBearerToken) {
            fetchRequestBody.headers.Authorization = `Bearer ${window.popdockBearerToken}`;
        }
        const results = await fetch(endpoint, fetchRequestBody);
        if (results.ok) {
            const resultsText = await results.text();
            if (resultsText) {
                const resultsJson = JSON.parse(resultsText);
                if (resultsJson && resultsJson.Redirect && window.location.href.replace(window.location.origin, "") !== resultsJson.Redirect) {
                    window.location = resultsJson.Redirect;
                }
                return resultsJson;
            } else {
                return resultsText;
            }
        } else {
            return {
                status: results.status,
                error: await results.text()
            };
        }
    } catch (error) {
        console.log("post " + endpoint);
        console.log(error);
        return { error: error };
    }
}

export async function data_patch(endpoint, body, contentType) {
    try {
        const fetchRequestBody = {
            method: "PATCH",
            headers: {
                "Content-Type": contentType || "application/json",
                "Accept": "application/json"
            },
            mode: "cors",
            body: JSON.stringify(body)
        };
        if (window.popdockBearerToken) {
            fetchRequestBody.headers.Authorization = `Bearer ${window.popdockBearerToken}`;
        }
        const results = await fetch(endpoint, fetchRequestBody);
        if (results.ok) {
            const resultsText = await results.text();
            if (resultsText) {
                const resultsJson = JSON.parse(resultsText);
                if (resultsJson && resultsJson.Redirect && window.location.href.replace(window.location.origin, "") !== resultsJson.Redirect) {
                    window.location = resultsJson.Redirect;
                }
                return resultsJson;
            } else { 
                return resultsText;
            }
        } else {
            return {
                status: results.status,
                error: await results.text()
            };
        }
    } catch (error) {
        console.log("patch " + endpoint);
        console.log(error);
        return { error: error };
    }
}

export async function data_delete(endpoint) {
    try {
        const fetchRequestBody = {
            method: "DELETE",
            headers: {
                "Accept": "application/json"
            }
        };
        if (window.popdockBearerToken) {
            fetchRequestBody.headers = {
                "Authorization": `Bearer ${window.popdockBearerToken}`
            };
        }
        const results = await fetch(endpoint, fetchRequestBody);
        if (results.ok) {
            const resultsText = await results.text();
            if (resultsText) {
                const resultsJson = JSON.parse(resultsText);
                if (resultsJson && resultsJson.Redirect && window.location.href.replace(window.location.origin, "") !== resultsJson.Redirect) {
                    window.location = resultsJson.Redirect;
                }
                return resultsJson;
            } else {
                return resultsText;
            }
        } else {
            return {
                status: results.status,
                error: await results.text()
            };
        }
    } catch (error) {
        console.log("delete " + endpoint);
        console.log(error);
        return { error: error };
    }
}

export const useDataGet = (initialEndpoint) => {
    const abortController = new AbortController();
    const mounted = useRef(false);
    const loaded = useRef(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!mounted) {
            if (initialEndpoint) {
                query(initialEndpoint);
            }
            else {
                setLoading(false);
            }
        }
        mounted.current = true;
        return () => {
            abortController.abort();
        };
    }, []);

    const query = async (url) => {       
        setLoading(true);
        const response = await data_get(url, abortController.signal);
        if (response.error) {
            setError(response.error);
        }
        else {
            setData(response);
            if (error) {
                setError(undefined);
            }
        }
        setLoading(false);
        loaded.current = true;
        return response;
    };

    return [data, error, loading, query, loaded.current];
};

export function formatDateForPostBody(value) {
    if (!value) return null;
    const dateValue = new Date(value);
    if (!dateValue) return null;
    return dateValue.toISOString().split("T")[0];
}

