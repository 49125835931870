import React, { useContext, useState } from "react";

const HelpContext = React.createContext();

function HelpProvider({ children }) {
    const [stack, setStack] = useState([]);

    const push = (item) => {
        if (stack.length > 0 && stack[stack.length - 1].Id === item.Id && stack[stack.length - 1].Type === item.Type) {
            const newStack = [...stack];
            newStack[stack.length - 1] = { ...item };
            setStack(newStack);
        } else {
            setStack((prevStack) => [...prevStack, {...item }]);
        }
    };

    const pop = () => {
        if (stack.length === 0) {
            setStack([]);
            return null;
        } else {
            let newStack = stack.slice(0, stack.length - 1);
            setStack(newStack);
            return newStack[newStack.length - 1];
        }
    };

    const top = stack.length === 0 ? null : stack[stack.length - 1];

    const clear = () => { setStack([]); };

    return (
        <HelpContext.Provider value={{ push, pop, top, clear }} >
            {children}
        </HelpContext.Provider>
    );
}

function useHelpContext() {
    const helpContext = useContext(HelpContext);
    return helpContext;
}

export { HelpProvider, useHelpContext };
