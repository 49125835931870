import React from "react";
import {FormInput} from "./form-input";
import {getDataTestId} from "../functions/data-test-ids";
import "./forms.css";

function FormSkeleton(props) {
    const dataTestId = getDataTestId(props.DataTestId, "skeleton");
    return (
        <FormInput Label={props.Label} DataTestId={dataTestId}>
            <div className="eone-ui-skeleton"/>
        </FormInput>
    );
}

export {FormSkeleton};