import React from "react";
import { ModalButtonType, ModalWidth } from "eone-ui/modal/modal-constants";

import { Modal, ModalForm, ModalMessage } from "eone-ui/modal/modal";
import { FormLabel } from "eone-ui/forms/form-label";
import { FormString } from "eone-ui/forms/form-string";
import { FormSpacer } from "eone-ui/forms/form-spacer";
import { faUser } from "@fortawesome/pro-regular-svg-icons";

function UserModal(props) {

    const getFooterButtons = () => {
        return [
            {
                Label: props.User ? "Logout" : "Sign in",
                Type: ModalButtonType.Primary,
                Visible: true,
                OnClick: props.Logout
            },
            {
                Label: "Close",
                Type: ModalButtonType.Secondary,
                Visible: true,
                OnClick: props.Close
            }
        ];
    };

    if (!props.User) {
        return (
            <Modal Show={props.Show} Title={"User"} Width={ModalWidth.Narrow} Close={props.Close} FooterButtons={getFooterButtons()}>
                <ModalForm>
                    <ModalMessage Message="You are not signed in" />
                </ModalForm>
            </Modal>
        );
    }


    return (
        <Modal Show={props.Show} Title={"User"}Width={ModalWidth.Narrow} Close={props.Close} FooterButtons={getFooterButtons()}>
            <ModalForm>
                <FormString Label={"Name"} Value={props.User.Name} ReadOnly />
                <FormString Label={"Email"} Value={props.User.Email} ReadOnly />
                <FormString Label={"User ID"} Value={props.User.UserId} ReadOnly />
                <FormString Label={"Account"} Value={props.User.AccountName} ReadOnly />
                <FormString Label={"Account ID"} Value={props.User.AccountId} ReadOnly />
                <FormString Label={"Region"} Value={props.User.RegionName} ReadOnly />
            </ModalForm>
        </Modal>
    );
}

export { UserModal };