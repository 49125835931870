import { useEffect, useRef, useState } from "react";
import { isNullOrUndefined } from "../functions/general";
import "./drag-panel.css";

const DEFAULT_MIN_WIDTH = 250;
const DEFAULT_MAX_WIDTH  = 2500;
const DEFAULT_INITIAL_WIDTH = 300;

const clampWidth = (value, min, max) => {
    const minValue = isNullOrUndefined(min) ? DEFAULT_MIN_WIDTH : min;
    const maxValue = isNullOrUndefined(max) ? DEFAULT_MAX_WIDTH : max;

    if (value < minValue) {
        return minValue;
    }
    if (value > maxValue) {
        return maxValue;
    }
    return value;
}

function HorizontalDragPanel(props) {
    const clamp = (value) => clampWidth(value, props.MinWidth, props.MaxWidth);

    const [dragging, setDragging] = useState(false);
    const [panelWidth, setPanelWidth] = useState(clamp(props.InitialWidth || DEFAULT_INITIAL_WIDTH));
    const panelRef = useRef(null);

    const onMouseMove = (e) => {
        if (dragging && panelRef.current) {
            const panelRect = panelRef.current.getBoundingClientRect();
            const newWidth = panelRect.right - e.clientX;
            setPanelWidth(clamp(newWidth));
            e.preventDefault();
        }
    };

    const onMouseUp = () => {
        document.body.classList.remove("pd-no-select");
        setDragging(false);
        if (props.SetIsDragging) {
            props.SetIsDragging(false);
        }
    };

    const onMouseDown = () => {
        document.body.classList.add("pd-no-select");
        setDragging(true);
        if (props.SetIsDragging) {
            props.SetIsDragging(true);
        }
    };

    useEffect(() => {
        if (dragging) {
            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
        } else {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        }
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, [dragging]);

    if (props.Visible != undefined && !props.Visible) {
        return null;
    }

    if (props.PanelVisible !== undefined && !props.PanelVisible) {
        return props.children;
    }

    return (
        <div className="pd-horizontal-drag-panel-container" style={{ "--pd-drag-panel-width": panelWidth + "px" }} >
            <div className={`pd-horizontal-drag-handle ${dragging ? "dragging" : ""}`} onMouseDown={onMouseDown}>
                <div className={`pd-horizontal-drag-handle-button ${dragging ? "dragging" : ""}`}></div>
            </div>
            <div className="pd-horizontal-drag-panel" ref={panelRef} style={{ width: `calc(${panelWidth}px - var(--drag-handle-thickness))` }}>
                {props.children}
            </div>
        </div>
    );
}


const DEFAULT_MIN_HEIGHT = 150;
const DEFAULT_MAX_HEIGHT = Number.MAX_SAFE_INTEGER;
const DEFAULT_INITIAL_HEIGHT = 300;

const clampHeight = (value, min, max) => {
    const minValue = isNullOrUndefined(min) ? DEFAULT_MIN_HEIGHT : min;
    const maxValue = isNullOrUndefined(max) ? DEFAULT_MAX_HEIGHT : max;

    if (value < minValue) {
        return minValue;
    }
    if (value > maxValue) {
        return maxValue;
    }
    return value;
}

function VerticalDragPanel(props) {
    const clamp = (value) => clampHeight(value, props.MinHeight, props.MaxHeight);

    const [dragging, setDragging] = useState(false);
    const [panelHeight, setPanelHeight] = useState(clamp(props.InitialHeight || DEFAULT_INITIAL_HEIGHT));
    const panelRef = useRef(null);
    const startY = useRef(0);
    const startHeight = useRef(clamp(props.InitialHeight || DEFAULT_INITIAL_HEIGHT));

    const onMouseMove = (e) => {
        if (dragging) {
            const deltaY = e.clientY - startY.current;
            const newHeight = startHeight.current - deltaY;
            setPanelHeight(clamp(newHeight));
        }
    };

    const onMouseUp = () => {
        document.body.classList.remove("pd-no-select");
        setDragging(false);
        if (props.SetIsDragging) {
            props.SetIsDragging(false);
        }
    };

    const onMouseDown = (e) => {
        document.body.classList.add("pd-no-select");
        setDragging(true);
        if (props.SetIsDragging) {
            props.SetIsDragging(true);
        }
        startY.current = e.clientY;
        startHeight.current = panelRef.current.getBoundingClientRect().height;
    };

    useEffect(() => {
        if (dragging) {
            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
        } else {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        }
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, [dragging]);

    if (!props.Visible || !props.children) {
        return null;
    }

    if (props.PanelVisible !== undefined && !props.PanelVisible) {
        return props.children;
    }

    return (
        <div className="pd-vertical-drag-panel-container" style={{ "--pd-drag-panel-height": panelHeight + "px" }}>
            <div className={`pd-vertical-drag-handle ${dragging ? "dragging" : ""}`} onMouseDown={onMouseDown}>
                <div className={`pd-vertical-drag-handle-button ${dragging ? "dragging" : ""}`}></div>
            </div>
            <div className="pd-vertical-drag-panel" ref={panelRef} style={{ height: `calc(${panelHeight}px - var(--drag-handle-thickness))` }}>
                {props.children}
            </div>
        </div>
    );
}

export { HorizontalDragPanel, VerticalDragPanel };
