import React, {useState, useEffect} from "react";
import "./modal.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faSpinner, faCircleInfo as fasCircleInfo} from "@fortawesome/pro-solid-svg-icons";
import {faCircleInfo, faCircleQuestion} from "@fortawesome/pro-regular-svg-icons";
import {ModalButtonAlignment, ModalButtonType, ModalWidth} from "./modal-constants";
import ReactDOM from "react-dom";
import {getDataTestId} from "../functions/data-test-ids";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {useHelpContext} from "../help-context";
import {Button} from "../buttons/button";
import { FontAwesome } from "../icons/icons";

const Markdown = React.lazy(() => import("../markdown/markdown"));

export function ModalExplanation(props) {
    if (!props.Explanation || !props.Explanation.Message) {
        return null;
    }
    
    return (
        <div className="eone-ui-modal-explanation">
            <Markdown>
                {props.Explanation.Message}
            </Markdown>
        </div>
    );
}

export function ModalMessage(props) {
    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    
    if (props.Spinner) {
        return (
            <div className="eone-ui-modal-message-container">
                <FontAwesomeIcon icon={faSpinner} spin={true}/>
                <label className="eone-ui-modal-message" data-testid={props.DataTestId}>{props.Message}</label>
            </div>
        );
    }
    
    return (
        <div className="eone-ui-modal-message-container">
            <label data-testid={props.DataTestId}>{props.Message}</label>
        </div>
    );
}

export function ModalSection(props) {
    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }
    
    return (
        <div className="eone-ui-modal-section-container">
            <label className="eone-ui-modal-section-title">{props.Title}</label>
            <div className="eone-ui-modal-section-body">
                {props.children}    
            </div>
        </div>
    );
}

export function ModalForm(props) {
    const [body, enableAnimations] = useAutoAnimate();
    
    if (props.Visible !== undefined && !props.Visible) {
        return null;
    }

    if (props.Animate) {
        return (
            <div className="eone-ui-modal-form" ref={body}>
                {props.children}
            </div>
        );
    }
    
    return (
        <div className="eone-ui-modal-form">
            {props.children}
        </div>
    );
}

export function ModalFooterButton(props) {
    const getClass = () => {
        switch (props.Type) {
            case ModalButtonType.Primary:
                return "eone-ui-modal-footer-button-primary";
            case ModalButtonType.Secondary:
                return "eone-ui-modal-footer-button-secondary";
            case ModalButtonType.Danger:
                return "eone-ui-modal-footer-button-danger";
            case ModalButtonType.Link:
                return "eone-ui-modal-footer-button-link";
            case ModalButtonType.Processing:
                return "eone-ui-modal-footer-button-processing";
            default:
                return "";
        }
    };
    
    if (!props.Visible) {
        return null;
    }
    
    const className = "eone-ui-modal-footer-button " + getClass();
    if (props.Icon) {
        return (
            <button type="button" className={className} title={props.Title || props.Label} onClick={props.OnClick} data-testid={props.DataTestId}>
                <FontAwesomeIcon icon={props.Icon} spin={props.IconSpin} fixedWidth/>
                <div className="eone-ui-modal-footer-button-label">{props.Label}</div>
            </button>
        );
    }
    
    return (
        <button type="button" className={className} title={props.Title || props.Label} onClick={props.OnClick} data-testid={props.DataTestId}>
            {props.Label}
        </button>
    );
}

function ModalHelpLinkButton(props) {
    const helpContext = useHelpContext();

    const navigateToHelp = () => {
        helpContext.push({ Id: props.HelpLink, Type: "Article" });
    };
    
    if (!props.HelpLink) {
        return null;
    }

    return (
        <Button Icon={faCircleQuestion} OnClick={navigateToHelp} Title="Show help"/>
    );
}

function ModalRequiredFieldsButton(props) {
    if (!props.RequiredFields) {
        return null;
    }
    
    if (!props.RequiredFields.Visible) {
        return null;
    }
    
    return (
        <Button
            Icon={props.RequiredFields.Show ? fasCircleInfo : faCircleInfo} 
            OnClick={props.RequiredFields.Toggle}/>
    );
}

function ModalFooter(props) {
    const getRightButtons = () => {
        if (!props.Buttons) {
            return null;
        }

        return props.Buttons.filter(button => button.Alignment === ModalButtonAlignment.Right).map((button, index) => {
            return (
                <ModalFooterButton
                    key={index}
                    Label={button.Label}
                    Title={button.Title}
                    Icon={button.Icon}
                    IconSpin={button.IconSpin}
                    Type={button.Type}
                    Visible={button.Visible}
                    OnClick={button.OnClick}
                    DataTestId={getDataTestId(props.DataTestId, button.Label)}/>
            );
        });
    };
    
    const getLeftButtons = () => {
        if (!props.Buttons) {
            return null;
        }

        return props.Buttons.filter(button => button.Alignment !== ModalButtonAlignment.Right).map((button, index) => {
            return (
                <ModalFooterButton
                    key={index}
                    Label={button.Label}
                    Title={button.Title}
                    Icon={button.Icon}
                    IconSpin={button.IconSpin}
                    Type={button.Type}
                    Visible={button.Visible}
                    OnClick={button.OnClick}
                    DataTestId={getDataTestId(props.DataTestId, button.Label)}/>
            );
        });
    };

    if (!props.Visible) { 
        return null;
    }
    if (props.CustomFooter) {
        return props.CustomFooter;
    }
    return (
        <div className="eone-ui-modal-footer">
            <div className="eone-ui-modal-footer-button-container">
                <ModalHelpLinkButton HelpLink={props.HelpLink}/>
                <ModalRequiredFieldsButton RequiredFields={props.RequiredFields}/>
                {getRightButtons()}
            </div>
            <div className="eone-ui-modal-footer-button-container">
                {getLeftButtons()}
            </div>
        </div>
    );
}

function ModalHeaderButton(props) {
    if (!props.Visible) {
        return null;
    }

    if (props.Icon) {
        return (
            <button type="button" className="eone-ui-modal-header-button" title={props.Title || props.Label} onClick={props.OnClick} data-testid={props.DataTestId}>
                <FontAwesomeIcon icon={props.Icon} fixedWidth/>
                <div className="eone-ui-modal-header-button-label">{props.Label}</div>
            </button>
        );
    }

    return (
        <button type="button" className="eone-ui-modal-header-button" title={props.Title || props.Label} onClick={props.OnClick} data-testid={props.DataTestId}>
            {props.Label}
        </button>
    );
}
 
function ModalHeader(props) {
    const getButtons = () => {
        if (!props.Buttons || props.Buttons.length === 0) {
            return null;
        }
        
        return props.Buttons.map((button, index) => {
            return (
                <ModalHeaderButton
                    key={index}
                    Visible={button.Visible}
                    Title={button.Title}
                    Label={button.Label}
                    Icon={button.Icon}
                    OnClick={button.OnClick}
                    DataTestId={getDataTestId(props.DataTestId, button.Label)}/>
            );
        });
    };
    
    return (
        <div className="eone-ui-modal-header">
            <div className="eone-ui-modal-header-buttons">
                {getButtons()}
            </div>
            <div className="eone-ui-modal-header-title">{props.Title}</div>
            <div className="eone-ui-modal-close-button" onClick={props.Close} data-testid={getDataTestId(props.DataTestId, "close-button")}>
                <FontAwesome Visible={!props.HideClose} Icon={faClose} ClassName="eone-ui-modal-close-button-icon" />
            </div>
        </div>
    );
} 

const ModalPortal = ({ children }) => {
    const container = document.getElementById("modal-root");
    if (container)
        return ReactDOM.createPortal(children, container);
    return children;
};

export function Modal(props) {
    const closeModal = () => {
        props.Close();
    };

    const getClass = () => {
        switch (props.Width) {
            case ModalWidth.Narrow:
                return "eone-ui-modal eone-ui-modal-narrow";
            case ModalWidth.ExtraNarrow:
                return "eone-ui-modal eone-ui-modal-extra-narrow";
            case ModalWidth.Wide:
                return "eone-ui-modal eone-ui-modal-wide";
            case ModalWidth.ExtraWide:
                return "eone-ui-modal eone-ui-modal-extra-wide";
            default:
                return "eone-ui-modal eone-ui-modal-normal";
        }
    };

    if (!props.Show) {
        return null;
    }
    return (
        <ModalPortal>
            <div className={props.Show ? "eone-ui-modal-container fade-in" : "eone-ui-modal-container fade-out"}>
                <div className={getClass()}>
                    <ModalHeader
                        Title={props.Title}
                        Close={closeModal}
                        HideClose={props.HideClose}
                        Buttons={props.HeaderButtons}
                        DataTestId={props.DataTestId} />
                    <div className="eone-ui-modal-body" >
                        {props.children}
                    </div>
                    <ModalFooter
                        CustomFooter={props.CustomFooter}
                        Visible={!props.HideFooter}
                        Buttons={props.FooterButtons}
                        RequiredFields={props.RequiredFields}
                        DataTestId={props.DataTestId}
                        HelpLink={props.HelpLink} />
                </div>
            </div>
        </ModalPortal>
    );
}