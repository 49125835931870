import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { ErrorPanel } from "eone-ui/panels/error-panel";

class NotAuthorizedErrorPanel extends Component {
    render() {
        return (
            <ErrorPanel
                Title="Not authorized"
                Message="You don't have access to that"
                Image="cloudy_stop" />

        );
    }
}

export { NotAuthorizedErrorPanel }