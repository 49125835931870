import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
    faAngleDown,
    faAngleUp,
    faSearch
} from "@fortawesome/pro-regular-svg-icons";
import {
    faCircle,
    faArrowCircleRight as fasArrowCircleRight
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { HorizontalDragPanel } from "../drag-handle/drag-panel";
import { getDataTestId } from "../functions/data-test-ids";
import { FontAwesome, Icon } from "../icons/icons";
import { InputString } from "../inputs/input-string";
import "./navigation.css";
import { NavigationSidebar } from "./sidebar/navigation-sidebar";

function NavigationCollapseButton(props) {
    const hasRotated = useRef(false);

    if (!props.Visible) {
        return null;
    }

    const toggleCollapse = () => {
        props.ToggleCollapse();
        hasRotated.current = true;
    };

    const title = props.Collapsed ? "Expand" : "Collapse";
    const iconClass = `${hasRotated ? "rotate" : "position"}-collapse-menu-button${props.Collapsed ? "" : "-reverse"}`;
    return (
        <div className="eone-ui-navigation-collapse-button-container">
            <a className="eone-ui-navigation-collapse-button" data-testid={props.DataTestId} onClick={toggleCollapse} title={title}>
                <FontAwesomeIcon key={props.Collapsed} className={iconClass} size="2x" icon={fasArrowCircleRight} />
            </a>
            <div className="eone-ui-navigation-collapse-button-background">
                <FontAwesomeIcon size="3x" icon={faCircle} />
            </div>
        </div>
    );
}

function NavigationHeader(props) {
    if (!props.Visible) {
        return null;
    }

    return (
        <label className="eone-ui-navigation-menu-header" data-testid={getDataTestId(props.DataTestId, "title")}>{props.Label}</label>
    );
}

function NavigationSpace() {
    const getStyle = () => {
        return {
            background: "none",
            borderLeft: "none",
            borderRight: "none",
            padding: 6
        };
    };

    return (
        <a className="list-group-item" style={getStyle()}></a>
    );
}

function NavigationButton(props) {
    const clickHandler = () => {
        if (typeof props.OnClick === "function") {
            props.OnClick();
        }
    };

    if (props.Visible !== undefined && props.Visible !== null && props.Visible === false) {
        return null;
    }

    if (props.Collapsed) {
        if (props.Disabled) {
            const title = props.Label + " - " + (props.DisabledMessage || "This feature requires a subscription that your account does not have enabled")
            return (
                <div className="eone-ui-navigation-menu-item-disabled" data-testid={props.DataTestId} title={title}>
                    <Icon Icon={props.Icon} IconStyle={props.IconStyle} Spin={props.IconSpin} FixedWidth />
                </div>
            );
        }
        return (
            <a title={props.Label} className="eone-ui-navigation-menu-item-collapsed" onClick={clickHandler} data-testid={props.DataTestId}>
                <div>
                    <Icon Icon={props.Icon} IconStyle={props.IconStyle} Spin={props.IconSpin} FixedWidth />
                </div>
            </a>
        );
    }

    if (props.Disabled) {
        return (
            <div className="eone-ui-navigation-menu-item-disabled" data-testid={props.DataTestId} title={props.DisabledMessage || "This feature requires a subscription that your account does not have enabled"}>
                <div>
                    <Icon Icon={props.Icon} IconStyle={props.IconStyle} Spin={props.IconSpin} FixedWidth />
                    <span className="eone-ui-navigation-menu-item-label-disabled">{props.Label}</span>
                </div>
            </div>
        );
    }

    return (
        <a className="eone-ui-navigation-menu-item" onClick={clickHandler} data-testid={props.DataTestId}>
            <div>
                <Icon Icon={props.Icon} IconStyle={props.IconStyle} Spin={props.IconSpin} FixedWidth />
                <span className="eone-ui-navigation-menu-item-label">{props.Label}</span>
            </div>
        </a>
    );
}

function NavigationButtons(props) {
    const getButtons = () => {
        return props.Buttons.map((button, index) => {
            return (
                <NavigationButton
                    key={index}
                    Label={button.Label}
                    Icon={button.Icon}
                    IconStyle={button.IconStyle}
                    IconSpin={button.IconSpin}
                    Visible={button.Visible}
                    Disabled={button.Disabled}
                    DisabledMessage={button.DisabledMessage}
                    OnClick={button.OnClick}
                    AppMenu={props.AppMenu}
                    Collapsed={props.Collapsed}
                    DataTestId={getDataTestId(props.DataTestId, button.Label)} />
            );
        });
    };

    if (!props.Buttons || props.Buttons.filter(b => b.Visible !== false).length === 0) {
        return null;
    }

    const containerClass = props.Collapsed ? "eone-ui-navigation-menu-item-container-collapsed" : "eone-ui-navigation-menu-item-container";
    const label = props.ActionsLabel || "Actions";
    return (
        <div>
            <NavigationHeader Visible={!props.Collapsed} Label={label} DataTestId={props.DataTestId} />
            <div className={containerClass}>
                {getButtons()}
            </div>
        </div>
    );
}

function NavigationInfoItem(props) {
    if (props.Header) {
        if (props.Icon) {
            return (
                <div className="list-group-item list-group-item-error">
                    <FontAwesome Icon={props.Icon} Style={props.IconStyle} FixedWidth />
                    <span className="eone-ui-nav-item-header">{props.Label}</span>
                </div>
            );
        }
        return (
            <div className="list-group-item">
                <span className="eone-ui-nav-item-header">{props.Label}</span>
            </div>
        );
    }

    return (
        <div className="list-group-item">
            <span className="eone-ui-navigation-badge">{props.Badge}</span>{props.Label}
        </div>
    );
}

function NavigationInfo(props) {
    const getItems = () => {
        return props.Items.map((item, index) => {
            return (
                <NavigationInfoItem
                    key={index}
                    Header={item.Header}
                    Label={item.Label}
                    Icon={item.Icon}
                    IconStyle={item.IconStyle}
                    Badge={item.Badge} />
            );
        });
    };

    if (!props.Items || props.Items.length === 0) {
        return null;
    }
    return (
        <ul className="list-group">
            {getItems()}
        </ul>
    );
}

function NavigationItemBadge(props) {
    if (!props.Text && !props.Icon) {
        return null;
    }
    
    if (props.Icon) {
        return (
            <span className="eone-ui-navigation-badge" title={props.Title} data-testid={props.DataTestId}>
                <FontAwesome Icon={props.Icon} Style={props.IconStyle} />
            </span>
        );
    }
    
    return (
        <span className="eone-ui-navigation-badge" title={props.Title} data-testid={props.DataTestId}>{props.Text}</span>
    );
}

function NavigationItem(props) {
    const clickHandler = () => {
        if (typeof props.OnClick === "function") {
            props.OnClick(props.Item);
        }
    };

    const getLinkClass = () => {
        let className = "eone-ui-navigation-menu-item";
        if (props.Collapsed) className = className + "-collapsed";
        if (props.Active) className = className + " eone-ui-navigation-menu-item-selected";
        return className;
    };

    const getLinkStyle = () => {
        if (props.Indent) {
            return { paddingLeft: props.Indent };
        }
        return null;
    };

    const getIconType = () => {
        if (props.IconType) {
            return props.IconType;
        }
        return "fa";
    };

    if (props.Label === undefined || props.Label === null || props.Label === "") {
        return null;
    }

    if (props.Collapsed) {
        if (props.Disabled) {
            const title = `${props.Label} - ${props.DisabledMessage || "This feature requires a subscription that your account does not have enabled."}`;
            return (
                <div title={title} className="eone-ui-navigation-menu-item-disabled" style={getLinkStyle()} data-testid={props.DataTestId}>
                    <div>
                        <Icon Icon={props.Icon} Type={getIconType()} IconStyle={props.IconStyle} Spin={props.IconSpin} FixedWidth />
                    </div>
                </div>
            );
        }
        const title = props.BadgeText ? `${props.Label} (${props.BadgeText})` : props.Label;
        return (
            <a title={title} className={getLinkClass()} style={getLinkStyle()} accessKey={props.AccessKey} onClick={clickHandler} data-testid={props.DataTestId}>
                <div>
                    <Icon Icon={props.Icon} Type={getIconType()} IconStyle={props.IconStyle} Spin={props.IconSpin} FixedWidth />
                </div>
            </a>
        );
    }

    if (props.Disabled) {
        return (
            <div className="eone-ui-navigation-menu-item-disabled" style={getLinkStyle()} data-testid={props.DataTestId} title={props.DisabledMessage || "This feature requires a subscription that your account does not have enabled"}>
                <div className="eone-ui-navigation-menu-item-label-container">
                    <Icon Icon={props.Icon} Type={getIconType()} IconStyle={props.IconStyle} FixedWidth />
                    <span className="eone-ui-navigation-menu-item-label-disabled">{props.Label}</span>
                </div>
            </div>
        );
    }

    return (
        <a className={getLinkClass()} style={getLinkStyle()} accessKey={props.AccessKey} onClick={clickHandler} data-testid={props.DataTestId}>
            <div className="eone-ui-navigation-menu-item-label-container">
                <Icon Icon={props.Icon} Type={getIconType()} IconStyle={props.IconStyle} Spin={props.IconSpin} FixedWidth />
                <span className="eone-ui-navigation-menu-item-label">{props.Label}</span>
            </div>
            <NavigationItemBadge
                Class={props.BadgeClass}
                Text={props.BadgeText}
                Icon={props.BadgeIcon}
                IconStyle={props.BadgeIconStyle}
                Title={props.BadgeTitle}
                DataTestId={getDataTestId(props.DataTestId, "badge")} />
        </a>
    );
}

function NavigationSection(props) {
    const [searchValue, setSearchValue] = useState("");

    const toggleCollapse = () => {
        if (typeof props.ToggleCollapse === "function") {
            props.ToggleCollapse(props.Index);
        }
    };

    const clickHandler = (item) => {
        if (typeof props.OnClick === "function") {
            props.OnClick(item);
        }
    };

    const isItemSearched = (item) => {
        if (!item.Label) {
            return false;
        }

        if (searchValue && item.Label.toLowerCase().includes(searchValue.toLowerCase())) {
            return true;
        }

        if (props.SearchValue && item.Label.toLowerCase().includes(props.SearchValue.toLowerCase())) {
            return true;
        }

        return false;
    };

    const getSearchItems = () => {
        if (!searchValue && !props.SearchValue) {
            return props.Items;
        }

        let items = [];
        for (let i = 0, len = props.Items.length; i < len; i++) {
            if (isItemSearched(props.Items[i])) {
                items.push(props.Items[i]);
            }
        }
        return items;
    };

    const getItems = () => {
        if (!props.Items) {
            return null;
        }

        let items = getSearchItems();
        if (items.length === 0) {
            return null;
        }

        return items.map((item, index) => {
            if (item.Visible !== undefined && item.Visible !== null && item.Visible === false && item.Disabled !== true) {
                return null;
            }
            if (item.Space) {
                return (
                    <NavigationSpace key={item.Item} />
                );
            }
            const active = item.Item === props.Selected;
            let accessKey = index + 1;
            if (accessKey === 10) {
                accessKey = 0;
            }
            if (accessKey > 10) {
                accessKey = undefined;
            }
            return (
                <NavigationItem
                    key={index}
                    Item={item.Item}
                    Label={item.Label}
                    Icon={item.Icon}
                    IconType={item.IconType}
                    IconStyle={item.IconStyle}
                    IconSpin={item.IconSpin}
                    BadgeText={item.BadgeText}
                    BadgeIcon={item.BadgeIcon}
                    BadgeIconStyle={item.BadgeIconStyle}
                    BadgeClass={item.BadgeClass}
                    BadgeTitle={item.BadgeTitle}
                    Indent={item.Indent}
                    Active={active}
                    Disabled={item.Disabled}
                    AccessKey={accessKey}
                    AppMenu={props.AppMenu}
                    OnClick={clickHandler}
                    Collapsed={props.Collapsed}
                    DataTestId={getDataTestId(props.DataTestId, props.Label, item.Label)} />
            );
        });
    };

    if (props.Collapsed) {
        return (
            <div className="eone-ui-navigation-menu-header-container eone-ui-navigation-menu-header-container-collapsed">
                <label className="eone-ui-navigation-menu-header" data-testid={getDataTestId(props.DataTestId, props.Label)}>{props.Label}</label>
                <a className="eone-ui-navigation-menu-header-collapse-button" title="Expand section" data-testid={getDataTestId(props.DataTestId, props.Label, "expand")} onClick={toggleCollapse}>
                    <FontAwesomeIcon icon={faAngleDown} />
                </a>
            </div>
        );
    }

    return (
        <div>
            <div className="eone-ui-navigation-menu-header-container">
                <label className="eone-ui-navigation-menu-header" data-testid={getDataTestId(props.DataTestId, props.Label)}>{props.Label}</label>
                <a className="eone-ui-navigation-menu-header-collapse-button" title="Collapse section" data-testid={getDataTestId(props.DataTestId, props.Label, "collapse")} onClick={toggleCollapse}>
                    <FontAwesomeIcon icon={faAngleUp} />
                </a>
            </div>
            <InputString
                Visible={props.Search === true && props.Items.length > 10}
                ContainerClass="eone-ui-navigation-search-container"
                Icon={faSearch}
                Placeholder="Search"
                Value={searchValue}
                OnChange={setSearchValue}
                DataTestId={getDataTestId(props.DataTestId, props.Label, "search")} />
            <div className="eone-ui-navigation-menu-item-container">
                {getItems()}
            </div>
        </div>
    );
}

function Navigation(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [sectionCollapsed, setSectionCollapsed] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [items, enableItemAnimations] = useAutoAnimate();
    const hasCollapsed = useRef(false);

    const clickHandler = (item) => {
        props.OnClick(item);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
        hasCollapsed.current = true;
    };

    const toggleSectionCollapsed = (index) => {
        let newSectionCollapsed = sectionCollapsed.slice(0);
        if (newSectionCollapsed.includes(index)) {
            newSectionCollapsed = newSectionCollapsed.filter(item => item !== index);
        } else {
            newSectionCollapsed.push(index);
        }
        setSectionCollapsed(newSectionCollapsed);
    };

    const getSections = (dataTestId) => {
        return props.Sections.map((section, index) => {
            return (
                <NavigationSection
                    key={index}
                    Index={index}
                    Label={section.Label}
                    Items={section.Items}
                    Search={section.Search}
                    SearchValue={searchValue}
                    Selected={props.Selected}
                    Collapsed={sectionCollapsed.includes(index)}
                    OnClick={clickHandler}
                    ToggleCollapse={toggleSectionCollapsed}
                    DataTestId={dataTestId} />
            );
        });
    };

    const getItems = (dataTestId) => {
        if (!props.Items) {
            return null;
        }

        return props.Items.map((item, index) => {
            if (item.Visible !== undefined && item.Visible !== null && item.Visible === false) {
                return null;
            }
            if (item.Space) {
                return (
                    <NavigationSpace key={item.Item} />
                );
            }
            const active = item.Item === props.Selected;
            let accessKey = index + 1;
            if (accessKey === 10) {
                accessKey = 0;
            }
            if (accessKey > 10) {
                accessKey = undefined;
            }
            return (
                <NavigationItem
                    key={index}
                    Item={item.Item}
                    Label={item.Label}
                    Icon={item.Icon}
                    IconType={item.IconType}
                    IconStyle={item.IconStyle}
                    IconSpin={item.IconSpin}
                    BadgeText={item.BadgeText}
                    BadgeIcon={item.BadgeIcon}
                    BadgeIconStyle={item.BadgeIconStyle}
                    BadgeClass={item.BadgeClass}
                    BadgeTitle={item.BadgeTitle}
                    Indent={item.Indent}
                    Collapsed={collapsed}
                    Active={active}
                    Disabled={item.Disabled}
                    DisabledMessage={item.DisabledMessage}
                    AccessKey={accessKey}
                    AppMenu={props.AppMenu}
                    OnClick={clickHandler}
                    DataTestId={getDataTestId(dataTestId, item.Label)} />
            );
        });
    };

    const getItemsLabel = () => {
        if (props.Items && props.Items.length > 0) {
            if (props.ItemsLabel) {
                return props.ItemsLabel;
            }
            return "Settings";
        }
        return null;
    };

    const menuContainerClass = `eone-ui-navigation-menu-container ${collapsed ? "collapsed" : "expanded"}`;
    const itemContainerClass = collapsed ? "eone-ui-navigation-menu-item-container-collapsed" : "eone-ui-navigation-menu-item-container";

    if (props.Sections && props.Sections.length > 0) {
        const sectionDataTestId = getDataTestId(props.DataTestId, "navigation");
        return (
            <div className="eone-ui-navigation-container">
                <div id="navigation" className={menuContainerClass} >
                    <InputString
                        Visible={props.Search === true}
                        ContainerClass="eone-ui-navigation-search-all-container"
                        Icon={faSearch}
                        Placeholder="Search"
                        Value={searchValue}
                        OnChange={setSearchValue}
                        DataTestId={getDataTestId(sectionDataTestId, "search")} />
                    <div className="eone-ui-navigation-menu">
                        {getSections(sectionDataTestId)}
                    </div>
                    <NavigationCollapseButton
                        Visible={props.Collapsible}
                        Collapsed={collapsed}
                        ToggleCollapse={toggleCollapsed}
                        DataTestId={sectionDataTestId} />
                </div>
                <div className="eone-ui-navigation-body" >
                    {props.children}
                </div>
                <NavigationSidebar Sidebar={props.Sidebar} SetIsDragging={props.SetIsDragging} />
            </div>
        );
    }


    const dataTestId = getDataTestId(props.DataTestId, "navigation", props.ItemsLabel || "Settings");
    return (
        <div className="eone-ui-navigation-container">
            <div id="navigation" className={menuContainerClass}>
                <div className="eone-ui-navigation-menu" >
                    <NavigationHeader
                        Visible={!collapsed}
                        Label={getItemsLabel()}
                        DataTestId={dataTestId} />
                    <div className={itemContainerClass} ref={items}>
                        {getItems(dataTestId)}
                    </div>
                    <NavigationInfo
                        Items={props.InfoItems}
                        Visible={!collapsed}
                        DataTestId={getDataTestId(props.DataTestId, "navigation", "info")} />
                    <NavigationButtons
                        Collapsed={collapsed}
                        AppMenu={props.AppMenu}
                        Buttons={props.Buttons}
                        DataTestId={getDataTestId(props.DataTestId, "navigation", "actions")} />
                </div>
                <NavigationCollapseButton
                    Visible={props.Collapsible}
                    Collapsed={collapsed}
                    ToggleCollapse={toggleCollapsed}
                    DataTestId={getDataTestId(dataTestId, collapsed ? "expand-button" : "collapse-button")} />
            </div>
            <div className="eone-ui-navigation-body" >
                {props.children}
            </div>
            <NavigationSidebar Sidebar={props.Sidebar} SetIsDragging={props.SetIsDragging} />
        </div>
    );
}

export { Navigation, NavigationButtons };
